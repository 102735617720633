import React, { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  CreateAPIParams,
  SendRequestParams,
  TokenizedAPIParams,
  WebServerContextType,
} from "../types";

const baseUrlSec =
  process.env.REACT_APP_ENV !== "development" ? "https://" : "http://";
const baseURL = process.env.REACT_APP_BACKEND_ENDPOINT || "";
// const ACAO =
//   process.env.REACT_APP_ENV !== "development"
//     ? process.env.REACT_APP_ENV === "staging"
//       ? "https://mos-id-backend-staging.vectech.io"
//       : "https://mos-id-backend.vectech.io"
//     : "*";

const apiConfig = {
  mainAPI: {
    baseURL: baseUrlSec + baseURL,
  },
};

const WebServerContext = React.createContext<WebServerContextType | undefined>(
  undefined
);

const WebServerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup, isLoading, user } =
    useAuth0();

  const getToken = async (ignoreCache = false) => {
    try {
      return await getAccessTokenSilently();
      // return await getAccessTokenSilently({ cacheMode: "off" });
    } catch (e) {
      try {
        return await getAccessTokenWithPopup();
      } catch (e) {}
    }
  };

  const createApi = (params: CreateAPIParams) =>
    axios.create({
      baseURL: params.baseURL,
      headers: {
        Authorization: `Bearer ${params.token}`,
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": ACAO,
      },
    });

  const createTokenizedAPI = async (params: TokenizedAPIParams) => {
    const token = await getToken();
    return createApi({
      baseURL:
        params?.devPort && process.env.REACT_APP_ENV === "development"
          ? String(apiConfig.mainAPI.baseURL).slice(0, -4) + params.devPort
          : apiConfig.mainAPI.baseURL,
      token,
    });
  };

  const sendRequest =
    (method: string) =>
    async <T, D>(
      sendParams: SendRequestParams<D>
    ): Promise<AxiosResponse<T, D>> => {
      const { devPort, ...rest } = sendParams;
      const resp: AxiosRequestConfig = {
        ...rest,
        method,
      };
      const api = await createTokenizedAPI({ devPort });
      const response = await api.request(resp);
      return response;
    };

  return (
    <WebServerContext.Provider value={{sendRequest,
      getToken,
      isLoading,
      user,}}>
      {children}
    </WebServerContext.Provider>
  );
};

const useWebServer = () => {
  const context = useContext(WebServerContext);
  if (!context) {
    throw new Error("UseWebServer must be used within a WebServerProvider");
  }
  return context;
};

export default WebServerProvider;
export { useWebServer };
