
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import DevicePanel from './views/devicePanel';
import GeneralSetting from './views/generalSetting';
import Schedule from './views/schedule';
import Gallery from './views/gallery';
import { AuthLayout } from './components/PrivateRoute';
import {ROUTES} from './config/routes';
import './App.css';
import DeviceSetting from './views/deviceSetting';


const App: React.FC = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
      <Route element={<AuthLayout/>}>
        <Route path={ROUTES.home} element={<DevicePanel />} />
        {/* <Route path={ROUTES.devicePanel} element={<DevicePanel />} /> */}
        <Route path={ROUTES.settings} element={<GeneralSetting />} />
        <Route path={ROUTES.schedule} element={<Schedule />} />
        <Route path={ROUTES.gallery} element={<Gallery />} />
        <Route path={ROUTES.device_settings} element={<DeviceSetting />} />
      </Route>
      </Routes>
    </Router>
  );
};

export default App;