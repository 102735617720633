import React, { useState } from 'react';
import { Row, Col, Divider, Button, Spin } from 'antd';
import { ArrowLeftOutlined, RedoOutlined, SyncOutlined, CameraOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../config/routes';
import { ReducerState } from '../../types';
import { ConnectedProps, connect } from 'react-redux';
import DeviceUpdate from '../../components/DeviceUpdate';
import { putDeviceCommand } from '../../actions';
import { useWebServer } from '../../providers';
import axios from 'axios';

const mapStateToProps = (state: ReducerState) => {
    return {
      selected: state.devices?.selected || null, 
    }
  }
  const mapDispatchToProps = {
    putDeviceCommand,
  };
  
  const connector = connect(mapStateToProps, mapDispatchToProps);
  
  type PropsFromRedux = ConnectedProps<typeof connector>;

  const Index: React.FC<PropsFromRedux> = ({ selected, putDeviceCommand }) => {
    const { sendRequest, isLoading } = useWebServer();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleCommand = async(command: string) => {
        if(selected?.uuid){
            setLoading(true);
            const uuid = selected?.uuid;
            try {
                await putDeviceCommand(sendRequest, uuid, command);
              } catch (error) {
                console.error("Command execution failed", error);
              }
            setLoading(false);
        }
       
    }

    return (
        <Spin spinning={loading}>
            <div className="general_setting_container">
                { (selected)  && <Row>
                    <Col span={2} />
                    <Col span={20}>
                        <Row>
                            <Col span={24} className="heading_container">
                                <ArrowLeftOutlined onClick={() => navigate(ROUTES.home)} style={{fontSize: "2.5rem", cursor: "pointer"}} />
                                &nbsp;&nbsp;&nbsp;
                                <h1>Device Settings</h1>
                            </Col>
                            <Divider style={{margin: "10px"}} />
                            <Col span={24}>
                                <DeviceUpdate />
                            </Col>
                            <Col span={24}>
                                <label style={{fontSize: "1rem", fontWeight: "500", color: "#000"}}>Organization : {selected?.org}</label>
                            </Col>
                            <Col span={24} style={{marginTop: "1rem"}}>
                                <Button type="primary" onClick={() => handleCommand("capture")} icon={<CameraOutlined />}>Capture</Button>&nbsp;
                                <Button type="primary" onClick={() => handleCommand("restart")} icon={<RedoOutlined />}>Restart</Button>&nbsp;
                                <Button type="primary" onClick={() => handleCommand("restart")} icon={<SyncOutlined />}>Refresh Schedule</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={2} />
                </Row>}
                
            </div>
        </Spin>
)};

export default connector(Index);