import React, {useState, useEffect} from 'react';
import BatteryGauge from 'react-battery-gauge'
import { useBattery } from 'react-use';

const Battery: React.FC = () => {
    const battery = useBattery();
    const [level, setLevel] = useState(0);

    useEffect(() => {
        if (battery?.isSupported && battery?.fetched && battery?.level) {
            const levelString = battery.level.toString();
            const decimalPart = levelString.split(".")[1];
            const decimalLength = decimalPart ? decimalPart?.length : 0;
            const level = decimalLength === 1 ? parseFloat(decimalPart + "0") :  (decimalPart ? parseFloat(decimalPart) : 100);
            setLevel(level);
        } else {
            setLevel(100);
        }
    },[battery]);
    
    return (
        <div className="component_box_container" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <h2>Battery</h2><br/>
            <BatteryGauge
                orientation='vertical'
                size={100}
                value={level}
                aspectRatio={0.5}
                customization={{
                    batteryBody: {
                    strokeColor: '#333',
                    strokeWidth: 1,
                    cornerRadius: 2,
                    },
                    batteryCap: {
                    strokeColor: '#333',
                    cornerRadius: 1,
                    strokeWidth: 1,
                    capToBodyRatio: 0.3,
                    },
                    batteryMeter: {
                    outerGap: 1,
                    noOfCells: 10,
                    },
                    readingText: {
                    lowBatteryColor: 'red',
                    fontSize: 16,
                    style: { filter: 'url(#shadow)' },
                    },
                }}
             />
             {/* <h3>Estimate Remaining Time :</h3>
             <h1>12 Days</h1> */}
        </div>
    )
};

export default Battery;