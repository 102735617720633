import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Col, Form, Row, notification } from 'antd';
import { useWebServer } from '../../providers';
import { ReducerState } from '../../types';
import { listDevices, scheduleDeviceUpdate, selectDevice } from '../../actions';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../config/routes';
import DeviceSelector from './DeviceSelector';
import UpdateButton from './UpdateButton';
import { SettingFilled } from '@ant-design/icons'
import './index.css'

const mapStateToProps = (state: ReducerState) => {  
  return {
    count: state.devices?.count,
    devices: state.devices?.devices || {},
    selected: state.devices?.selected, 
  }
}

const connector = connect(mapStateToProps, {
  listDevices,
  selectDevice,
  scheduleDeviceUpdate,
});

type Props = ConnectedProps<typeof connector> & {
  // Extra props go here
};

const Index: React.FC<Props> = ({count, devices, selected, listDevices, selectDevice, scheduleDeviceUpdate}) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const { sendRequest, isLoading } = useWebServer();
  const [ loading, setLoading ] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!selected?.uuid) {
      navigate(ROUTES.home);
    }
    form.setFieldValue("device", {
      value: selected?.uuid || "",
      label: <span>{selected?.display_name || ""}</span>
    });
  }, [navigate, selected, form]);

  useEffect(() => {
    const getDeviceList = async () => {
      setLoading(true);
      await listDevices(sendRequest);
      setLoading(false);
  }
    if (!isLoading && count === 0) {
      getDeviceList();
    }
  }, [isLoading, count, sendRequest, listDevices]);

  useEffect(() => {
    const devicesWithUpdate = Object.values(devices)
      .filter(item => item.update_available)
      .map(item => item.display_name);

    if (devicesWithUpdate.length > 0) {
      api["warning"]({
        message: 'Update Available',
        description: (
          <>
            <p>Firmware update is available for the following devices:</p>
            <span>{devicesWithUpdate.join(', ')}</span>
          </>
        )
      });
    }
    
  },[devices, api])


    const handleSelect = async(value: any) => {
      setLoading(true);
      await selectDevice(sendRequest, value);
      setLoading(false);
    };

    const handleClickUpdate = async () => {
      if (selected?.uuid) {
        scheduleDeviceUpdate(sendRequest, selected.uuid, true);
      }
    }

    const handleCancelUpdate = async () => {
      if (selected?.uuid) {
        scheduleDeviceUpdate(sendRequest, selected.uuid, false);
      }
    }
    
    return (
      <>
        {contextHolder}
        
      <Row gutter={4} align="top" justify={'center'} wrap={false}>
        <Col span={21}>
          <DeviceSelector loading={loading} form={form} handleSelect={handleSelect} devices={devices}/>
        </Col>
        { selected?.display_name && 
          <Col span={1}>
            <SettingFilled onClick={() => navigate(ROUTES.device_settings)} style={{fontSize: "2rem", cursor: "pointer"}}/>
          </Col>
        }
        <Col span={2}>
          <UpdateButton selected={selected} handleClickUpdate={handleClickUpdate} handleCancelUpdate={handleCancelUpdate}/>
        </Col>
      </Row>
        </>
  )
};

export default connector(Index);
