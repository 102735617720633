import React, { useEffect } from 'react';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Space, notification } from 'antd';
import { useAuth0 } from "@auth0/auth0-react"
import "./index.css";
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../config/routes';
import { ReducerState } from '../../types';
import { ConnectedProps, connect } from 'react-redux';

const mapStateToProps = (state: ReducerState) => {
    return {
        notifications: state.notifications
    }
  }
  
  const connector = connect(mapStateToProps, {
  });

  type Props = ConnectedProps<typeof connector> & {
    // Extra props go here
  };

const Navbar: React.FC<Props> = ({notifications}) => {
    const { user, isAuthenticated, logout } = useAuth0();
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    useEffect(()=>{
        getNotify();
    },[notifications?.count])

    const getNotify = () => {
        const notiArray = Object.values(notifications?.notifications);
        if(notiArray?.length > 0){
            api[notiArray[notiArray?.length - 1].severity]({
                message: 'Notification',
                description: notiArray[notiArray?.length - 1].message,
                duration: 5,
            });
        }
    };

    const logoutWithRedirect = () =>
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
    });

    const handleLogoPress = () => {
        navigate(ROUTES.home);
    }

    const items: MenuProps['items'] = [
        {
          label: <a href="/#">{user?.name}</a>,
          key: '0',
        },
        {
            type: 'divider',
        },
        {
          label: <a href="/#" onClick={() => logoutWithRedirect()}>Logout</a>,
          key: '1',
        },    
    ]

    return (
        <>
            {contextHolder}
            {isAuthenticated && 
                (<nav className="flex align-center sticky">
                    <p className="logo-header" onClick={() => handleLogoPress()}><span>Sco</span>ut</p>
                    <ul>
                        <li className="big-screens">
                        
                        <Dropdown menu={{ items }} trigger={['click']} placement="bottomRight">
                            <a href="/#" onClick={(e) => e.preventDefault()}>
                                <Space>
                                    <UserOutlined style={{fontSize: "2rem"}} />
                                    <DownOutlined style={{fontSize: "1rem"}} />
                                </Space>
                            </a>
                        </Dropdown>
                        </li>
                    </ul>
                </nav>)
            }
        </>
    )
};

export default connector(Navbar);