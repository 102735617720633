import React, { useState } from 'react';
import { WifiOutlined } from '@ant-design/icons';
import DeviceSetup from '../../DeviceSetup';

const WifiComp: React.FC = () => {
    const [showSetup, setShowSetup] = useState(false);
    const handleClick = () => {
        setShowSetup(true);
    }
    if (showSetup) {
        return (
            <DeviceSetup handleClose={() => setShowSetup(false)}/>
        )
    }
    return (
        <div className="component_box_container" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <h2>Wifi</h2><br/>
            <WifiOutlined style={{fontSize: "9.6rem", cursor: "pointer"}} onClick={handleClick}/>
        </div>
    )
};

export default WifiComp;