import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Button, Form, Switch, InputNumber, Select, TimePicker, Spin, Input } from 'antd';
import { CreateScheduleForm, Schedule, createScheduleRequest, updateScheduleRequest } from '../../../types';
import { getCurrWeekByDayTime, fromCurrWeek } from '../../../utils';
import moment from 'moment';

type Props = {
  open: boolean;
  loading: boolean;
  onCreate: (initialData: createScheduleRequest) => Promise<void>;
  onUpdate: (_id: string, initialData: updateScheduleRequest) => Promise<void>;
  onDelete: (_id: string) => Promise<void>;
  onCancel: () => void;
  initialData: Schedule | null;
};

const CreateScheduleModal: React.FC<Props> = ({onCreate, onUpdate, onCancel, onDelete, open, initialData, loading}) => {

  const [form] = Form.useForm();
  const [allWeek, setAllWeek] = useState(false);
  const [periodValue, setPeriodValue] = useState<number>(30);

  useEffect(() => {
    if(open && initialData){
        console.log(`init start: ${moment(fromCurrWeek(initialData?.start, new Date()).toLocaleTimeString(), "hh:mm A")}`);
        const options: Intl.DateTimeFormatOptions = { weekday: 'long' };
        initialData?.end ?  setAllWeek(false) : setAllWeek(true);
        form.setFieldsValue({
            "start": moment(fromCurrWeek(initialData?.start, new Date()).toLocaleTimeString(), "hh:mm A"),
            "end": initialData?.end ? moment(fromCurrWeek(initialData?.end, new Date()).toLocaleTimeString(), "hh:mm A") : null,
            "period": initialData?.period,
            "startDay": fromCurrWeek(initialData?.start, new Date()).toLocaleDateString('en-US', options),
            "endDay": initialData?.end ? fromCurrWeek(initialData?.end, new Date()).toLocaleDateString('en-US', options) : "",
            "display_name": initialData?.display_name || "",
        })
    }
    
  }, [initialData, form, setAllWeek, open])


  useEffect(() => {
    if (open && initialData == null) {
      form.resetFields();
      setAllWeek(false);
    }
  }, [open, setAllWeek, form, initialData]);

  const weekDays = [
            {label: "Monday", value: "Monday"},
            {label: "Tuesday", value: "Tuesday"},
            {label: "Wednesday", value: "Wednesday"},
            {label: "Thursday", value: "Thursday"},
            {label: "Friday", value: "Friday"},
            {label: "Saturday", value: "Saturday"},
            {label: "Sunday", value: "Sunday"}
        ];

  const handleSubmit = (item: CreateScheduleForm) => {
    console.log(`start: ${item.start}`);
    let curr = {
        start: getCurrWeekByDayTime(item.startDay, item.start), 
        end: item?.end ? getCurrWeekByDayTime(item.endDay, item.end) : undefined,
        period: item?.period || initialData?.period || 30,
        enabled: initialData?.enabled || true,
        display_name: item?.display_name,
    };
    if (initialData === null) {
      onCreate(curr as createScheduleRequest);
    }
    else {
      onUpdate(initialData?._id, curr);
    }
  }

  const handlePeriodChange = (e: number | null) => {
    if (e !== null) {
        setPeriodValue(e);
        form.setFieldValue("period", e);
      }
    
  }

  return (
      <Modal
        open={open}
        width={650}
        title={initialData !== null ? "Update Scheduled Job" : "Add Schedule Job"}
        footer={null}
        onCancel={onCancel}
      >
          <Spin spinning={loading}>
              <Form
                  name="Timer"
                  form={form}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  onFinish={handleSubmit}
                  layout='vertical'
                  autoComplete="off"
              >
                  <Row style={{alignItems: "center"}}>
                      <Col span={10}>
                        <Form.Item
                            label="Period"
                            name="period"
                            initialValue={30}
                        >
                            <InputNumber
                                placeholder='Capture Period'
                                min={15}
                                style={{width: "10rem"}}
                                formatter={(value) => `${value} s`}
                                parser={(value) => {
                                    const parsedValue = value ? value.replace(/\s*s$/, '') : '';
                                    return Number(parsedValue);
                                  }}
                                value={periodValue}
                                onChange={handlePeriodChange}
                            />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                            label="Name"
                            name="display_name"
                        >
                            <Input
                                placeholder='Job Name (Optional)'
                                style={{width: "10rem"}}
                                />
                        </Form.Item>
                      </Col>
                      <Col span={4} >
                          <Switch value={allWeek} checkedChildren="All-Week" unCheckedChildren="All-Week" onChange={(b) => setAllWeek(b)} />
                      </Col>
                      <Col span={12}>
                          <Form.Item
                              label="Start day"
                              name="startDay"
                              rules={[{ required: true, message: 'Please select start day' }]}
                              style={{marginBottom: "0.5rem"}}
                          >
                              <Select options={weekDays} />
                          </Form.Item>
                      </Col>
                      <Col span={12}>
                          <Form.Item
                              label="Start time"
                              name="start"
                              rules={[{ required: true, message: 'Please select start time' }]}
                          >
                              <TimePicker format="hh:mm A" 
                              onFocus={() => {form.setFieldValue('start', undefined)}}
                              onBlur={() => {
                                if (form.getFieldValue('start') === undefined && initialData?.start) {
                                    form.setFieldValue('start', moment(fromCurrWeek(initialData?.start, new Date()).toLocaleTimeString(), "hh:mm A"));
                                    form.validateFields()
                                }
                              }}
                              changeOnBlur
                              />
                          </Form.Item>
                      </Col>
                      
                  </Row>
                  {allWeek ? "" :
                  <Row style={{alignItems: "center"}}>
                      <Col span={12}>
                          <Form.Item
                              label="End day"
                              name="endDay"
                              rules={[{ required: allWeek ? false : true, message: 'Please select end day' }]}
                          >
                              <Select options={weekDays} />
                          </Form.Item>
                      </Col>
                      <Col span={12}>
                          <Form.Item
                              label="End time"
                              name="end"
                              rules={[{ required: allWeek ? false : true, message: 'Please select end time' }]}
                          >
                              <TimePicker format="hh:mm A" 
                              onFocus={() => {form.setFieldValue('end', undefined)}}
                              onBlur={() => {
                                if (form.getFieldValue('end') === undefined && initialData?.end) {
                                    form.setFieldValue('end', initialData?.end ? moment(fromCurrWeek(initialData?.end, new Date()).toLocaleTimeString(), "hh:mm A") : null);
                                    form.validateFields()
                                }
                              }}
                              changeOnBlur
                              />
                          </Form.Item>
                      </Col>
                  </Row>}
                  <Row style={{justifyContent: "end"}}>
                      <Button key="back" onClick={onCancel}>
                          Cancel
                      </Button>&nbsp;&nbsp;
                      <Button type="primary" htmlType="submit">
                          {initialData !== null ? "Update" : "Submit"}
                      </Button>
                  </Row>
              </Form>
          </Spin>
                      
      </Modal>)
  }

export default CreateScheduleModal;