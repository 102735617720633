import {
  CREATE_DEVICE_SCHEDULE,
  DELETE_DEVICE_SCHEDULE,
  GET_NEXT_DEVICE_SCHEDULE,
  LIST_DEVICE_SCHEDULES,
  SELECT_DEVICE,
  UNSELECT_DEVICE,
  UPDATE_DEVICE_SCHEDULE,
} from "../actions";
import _ from "lodash";
import {
  ScheduleActions,
  ScheduleReducerState,
  SelectDeviceAction,
  UnselectDeviceAction,
} from "../types";

const defaultState: ScheduleReducerState = {
  count: 0,
  schedules: {},
  next: null,
  deviceUuid: null,
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (
  state = defaultState,
  action: ScheduleActions | SelectDeviceAction | UnselectDeviceAction
) => {
  switch (action.type) {
    case UNSELECT_DEVICE:
      return defaultState;
    case SELECT_DEVICE:
      if (state.deviceUuid !== action.payload.uuid) {
        return defaultState;
      }
      return state;
    case GET_NEXT_DEVICE_SCHEDULE:
      return {
        ...state,
        next: _.omit(action.payload, "deviceUuid"),
      };
    case LIST_DEVICE_SCHEDULES:
      return {
        ...state,
        schedules: action.payload.items.reduce(
          (o, x) =>
            x?._id
              ? {
                  ...o,
                  [x?._id]: {
                    ...x,
                    display_name:
                      x?.display_name ||
                      `Job ${x?._id?.substring?.(0, 6) || "0"}`,
                  },
                }
              : o,
          {}
        ),
        ..._.omit(action.payload, "items"),
      };
    case CREATE_DEVICE_SCHEDULE:
      return {
        ...state,
        count: state.count + 1,
        schedules: {
          ...state.schedules,
          [action.payload.scheduleUuid]: {
            start: action.payload.start,
            end: action.payload?.end || null,
            period: action.payload?.period || 30,
            enabled: action.payload?.enabled || true,
            display_name:
              action.payload?.display_name ||
              `Job ${action.payload.scheduleUuid?.substring?.(0, 6)}`,
          },
        },
      };
    case UPDATE_DEVICE_SCHEDULE:
      return _.defaultsDeep(
        {
          schedules: {
            ...state.schedules,
            [action.payload.scheduleUuid]: {
              ..._.omit(action.payload, ["deviceUuid", "scheduleUuid"]),
              display_name:
                action.payload?.display_name ||
                state.schedules?.[action.payload.scheduleUuid]?.display_name ||
                `Job ${action.payload.scheduleUuid?.substring?.(0, 6)}`,
            },
          },
        },
        state
      );
    case DELETE_DEVICE_SCHEDULE:
      return {
        ...state,
        count: state.count - 1,
        schedules: _.omit(state.schedules, [action.payload.scheduleUuid]),
      };
    default:
      return state;
  }
};
