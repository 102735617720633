import React from 'react';
import { Select, Spin, Form, FormInstance } from 'antd';
import { Device } from '../../types';
import { LoadingOutlined } from '@ant-design/icons';

const indicator = <LoadingOutlined style={{ fontSize: 15 }} spin />

type Props = {
  loading: boolean;
  form: FormInstance<any>;
  handleSelect: (value: any) => Promise<void>;
  devices: {[key: string]: Partial<Device>;}
};

const DeviceSelector: React.FC<Props> = ({loading, form, handleSelect, devices}) => {
  const deviceOptions = [{
      value: "",
      label: <span>{""}</span>
    }, 
    ...Object.values(devices).map(({uuid, display_name}) => ({value: uuid, label: <span>{display_name}</span>}))
  ];

    return (
      <>
          <Spin spinning={loading} indicator={indicator}>
          <Form
            form={form}
            name="control-hooks"
            style={{
              maxWidth: 600,
            }}
          >
            <Form.Item
              name="device"
              label="Please Select a Device"
            >
              <Select
                  showSearch
                  style={{ width: 400 }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  onSelect={handleSelect}
                  options={deviceOptions}
              />
            </Form.Item>
          </Form>
          </Spin>
      </>
    );
};

export default DeviceSelector;
