import { Dispatch } from "redux";
import {
  SendRequestCallback,
  listAlertsRequest,
  listAlertsResponse,
  updateResponse,
  ListAlertsAction,
  UpdateAlertAction,
  CreateNotificationAction,
  ClearAlertsAction,
  clearAlertsResponse,
  clearAlertsRequest,
} from "../../types";
import { displayErrorNotification } from "../notifications";
import { LIST_ALERTS, UPDATE_ALERT, CLEAR_ALERTS } from "../const";

export const listAlerts =
  (
    sendRequest: SendRequestCallback<
      undefined,
      listAlertsRequest,
      listAlertsResponse
    >,
    params: listAlertsRequest
  ) =>
  async (dispatch: Dispatch<ListAlertsAction | CreateNotificationAction>) => {
    try {
      const res = await sendRequest("GET")({
        url: `/alerts`,
        params,
      });
      if (res?.status === 200) {
        dispatch({
          type: LIST_ALERTS,
          payload: {
            ...res.data,
            page: params?.page || 0,
            pageSize: params?.pageSize || 10,
            tag: (params?.tags || []).join("|"),
            level: (params?.levels || []).join("|"),
          },
        });
      } else {
        throw Error("Status != 200");
      }
    } catch (error) {
      await displayErrorNotification("Failed to fetch alert")(dispatch);
    }
  };

export const updateAlerts =
  (
    sendRequest: SendRequestCallback<undefined, undefined, updateResponse>,
    id: string
  ) =>
  async (dispatch: Dispatch<UpdateAlertAction | CreateNotificationAction>) => {
    try {
      const res = await sendRequest("PUT")({
        url: `/alert/${id}`,
      });
      if (res?.status === 200) {
        dispatch({
          type: UPDATE_ALERT,
          payload: { _id: id, seen: true },
        });
      } else {
        throw Error("Status != 200");
      }
    } catch (error) {
      console.error("Failed to update alert!!");
      await displayErrorNotification("Failed to update alert")(dispatch);
    }
  };

export const clearAlerts =
  (
    sendRequest: SendRequestCallback<
      clearAlertsRequest,
      undefined,
      clearAlertsResponse
    >,
    deviceUuid: string,
    data: clearAlertsRequest
  ) =>
  async (dispatch: Dispatch<ClearAlertsAction | CreateNotificationAction>) => {
    try {
      const res = await sendRequest("PUT")({
        url: `/alerts/${deviceUuid}`,
        data,
      });
      if (res?.status === 200) {
        dispatch({
          type: CLEAR_ALERTS,
          payload: { deviceUuid: deviceUuid, tags: data.tags, success: true },
        });
      } else {
        throw Error("Status != 200");
      }
    } catch (error) {
      console.error("Failed to clear alert!!");
      await displayErrorNotification("Failed to clear alert")(dispatch);
    }
  };
