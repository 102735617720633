import {
  GET_DEVICE_ANALYTICS,
  SELECT_DEVICE,
  UNSELECT_DEVICE,
} from "../actions";
import {
  AnalyticsActions,
  AnalyticsReducerState,
  SelectDeviceAction,
  UnselectDeviceAction,
} from "../types";

const defaultState: AnalyticsReducerState = {
  deviceUuid: null,
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (
  state = defaultState,
  action: AnalyticsActions | SelectDeviceAction | UnselectDeviceAction
) => {
  switch (action.type) {
    case UNSELECT_DEVICE:
      return defaultState;
    case SELECT_DEVICE:
      if (state.deviceUuid !== action.payload.uuid) {
        return defaultState;
      }
      return state;
    case GET_DEVICE_ANALYTICS:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
