import {
    PUT_DEVICE_COMMAND
  } from "../actions";
  import _ from "lodash";
  import {
    PutDeviceCommandAction,
  } from "../types";
  
  const defaultState = {
    deviceUuid: null,
  };
  
  /* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
  export default (
    state = defaultState,
    action: PutDeviceCommandAction
  ) => {
    switch (action.type) {
      case PUT_DEVICE_COMMAND:
        return state;
  
      default:
        return state;
    }
  };
  