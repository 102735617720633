import React from 'react';
import { List, Card, Tooltip } from 'antd';
import { Capture } from '../../types';
import "./index.css";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ImageView from '../../components/ImageView';

const { Meta } = Card;

type Props = {
  data: Capture[]
  pageSize: number;
  count: number;
  handlePaginationChange: (page: number, pageSize: number) => void
};

const Gallery: React.FC<Props> = ({data, count, pageSize, handlePaginationChange}) => {
  const displayDate = (captured_at: string) => {
    if (captured_at) {
      const d = new Date(captured_at + (captured_at.endsWith('+00:00') ? "" : "+00:00"));
      const dateString = d.toLocaleString().replace(',', ' -');
      return dateString
    }
    return "";
  }
    
  return (
    <List
              grid={{ gutter: 16, xs: 2, sm: 3, md: 4, lg: 5, xl: 6, xxl: 6 }}
              pagination={{
                onChange: handlePaginationChange,
                pageSize: pageSize,
                defaultCurrent: 1,
                position: "top",
                align: "center",
                total: count,
              }}
              dataSource={data}
              renderItem={(item, index) => (
                <List.Item style={{ marginTop: "1rem" }} key={"spec_" + index} >
                  <Card
                    cover={<ImageView src={item?.img || null} className="spec_style"/>}
                  >
                  <Meta 
                    avatar={(
                      <Tooltip title="Failed Upload">
                      <ExclamationCircleOutlined
                        className="red-icon"
                        style={(item?.failed || false) ? {} : {visibility: 'hidden'} }
                        />
                        </Tooltip>
                      ) }
                    description={displayDate(item?.captured_at)}
                  />  
                  </Card>
                </List.Item>
              )}
            />
  )
};

export default Gallery