import React, { useState, useEffect, useRef } from 'react';
import { Spin } from 'antd';
import useImage from 'use-image';
import { imgNotFound } from '../assets/icons';


type Props = {
  src: string | null;
  width?: number | 'auto';
  height?: number | 'auto';
  className?: string;
};

const ImageView: React.FC<Props> = ({src, width='auto', height='auto', className = ""}) => {
    const [image, imgStatus] = useImage(src || '', 'anonymous');
    const [canvasWidth, setCanvasWidth] = useState(0);
    const [canvasHeight, setCanvasHeight] = useState(0);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
      if (width === 'auto' && height === 'auto') {
        setCanvasWidth(image?.width || 0);
        setCanvasHeight(image?.height || 0)
      }
      else if ((width && width !== 'auto') && !(height && height !== 'auto')) {
        setCanvasWidth(width);
        setCanvasHeight(width * ((image?.height || 0) / (image?.width || 1)));
      }
      else if (!(width && width !== 'auto') && (height && height !== 'auto')) {
        setCanvasWidth(height * ((image?.width || 0) / (image?.height || 1)));
        setCanvasHeight(height);
      }
      else if (width !== 'auto' && height !== 'auto') {
        setCanvasWidth(width);
        setCanvasHeight(height);
      }
    }, [image, width, height, setCanvasHeight, setCanvasWidth])

    useEffect(() => {
      const drawImage = () => {
        if (image) {
          const canvas = canvasRef.current;
          if (canvas) {
            const ctx = canvas.getContext("2d");
            if (ctx) {
              ctx.save();
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.rect(0, 0, canvas.width, canvas.height);
              ctx.fillStyle = "rgb(248,248,255)";
              ctx.fill();
              ctx.drawImage(image, 0, 0, canvasWidth, canvasHeight);
          
              ctx.restore();
            }
          }
        }
      };
      drawImage();
    }, [image, canvasWidth, canvasHeight]);

    if (src && imgStatus === "failed") {
      return (
        <div>
          <img height={170} src={imgNotFound} alt="Not Found" />
        </div>
      );
    }
    if (imgStatus === "loading" || !src) {
      return (
        <div>
          <Spin size="large"/>
        </div>
      );
    }

    return (
      <canvas
        ref={canvasRef}
        width={canvasWidth}
        height={canvasHeight}
        className={className}
      />
    )
};

export default ImageView;