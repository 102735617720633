import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Col, Row } from 'antd';
import "./index.css";
import { ReducerState } from '../../types';
import { listCaptures } from '../../actions';
import { useWebServer } from '../../providers';
import DeviceSelector from '../../components/DeviceSelector';
import Gallery from './gallery';

const mapStateToProps = (state: ReducerState) => {
  return {
      selected: state.devices?.selected?.uuid || null,
      count: state.captures.count,
      captures: Object.values(state.captures.captures),
      deviceUuid: state.captures.deviceUuid,
      currentPage: state.captures.page,
      currentPageSize: state.captures.pageSize,
  }
}

const connector = connect(mapStateToProps, {
  listCaptures
});

type Props = ConnectedProps<typeof connector> & {
  // Extra props go here
};

const Index: React.FC<Props> = ({selected, count, captures, deviceUuid, currentPage, currentPageSize, listCaptures}) => {
  const { sendRequest, isLoading } = useWebServer();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(18);

  useEffect(() => {
    if (!isLoading && selected && (page !== currentPage || pageSize !== currentPageSize || selected !== deviceUuid)) {
        listCaptures(sendRequest, selected, {page, pageSize});
    }
  }, [isLoading, count, selected, deviceUuid, page, currentPage, pageSize, currentPageSize, sendRequest, listCaptures]);

  const handlePaginationChange = (newPage: number, newPageSize: number) => {
    setPage(newPage - 1);
    setPageSize(newPageSize);
  }

  return (
    <div className="general_setting_container">
       <Col>
            <Row className="select_container">
                <DeviceSelector/>
            </Row>
            <Row >
              <Gallery data={captures} pageSize={currentPageSize} handlePaginationChange={handlePaginationChange} count={count}/>
            </Row>
           
        </Col>
      
    </div>
  );
};

export default connector(Index);
