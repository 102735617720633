import {
  LIST_DEVICE_CAPTURES,
  SELECT_DEVICE,
  UNSELECT_DEVICE,
} from "../actions";
import _ from "lodash";
import {
  CaptureActions,
  CaptureReducerState,
  SelectDeviceAction,
  UnselectDeviceAction,
} from "../types";

const defaultState: CaptureReducerState = {
  count: 0,
  captures: {},
  page: -1,
  pageSize: 10,
  deviceUuid: null,
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (
  state = defaultState,
  action: CaptureActions | SelectDeviceAction | UnselectDeviceAction
) => {
  switch (action.type) {
    case UNSELECT_DEVICE:
      return defaultState;
    case SELECT_DEVICE:
      if (state.deviceUuid !== action.payload.uuid) {
        return defaultState;
      }
      return state;
    case LIST_DEVICE_CAPTURES:
      return {
        captures: action.payload.items.reduce(
          (o, x) =>
            x._id
              ? {
                  ...o,
                  [x._id]: {
                    ...x,
                    captured_at: x?.captured_at || x.created_at,
                  },
                }
              : o,
          {}
        ),
        ..._.omit(action.payload, "items"),
      };
    default:
      return state;
  }
};
