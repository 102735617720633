import React, {useState} from 'react';
import { Row, Col, Divider, Input, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import "./index.css";
import Species from '../../components/GeneralSetting/Species';
import EditSpecies from '../../components/GeneralSetting/EditSpecies';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../config/routes';
import { ReducerState } from '../../types';
import { ConnectedProps, connect } from 'react-redux';

const mapStateToProps = (state: ReducerState) => {
    return {
      selected: state.devices?.selected || null, 
    }
  }
  
  const connector = connect(mapStateToProps, {
    });
  
  type Props = ConnectedProps<typeof connector> & {
    // Extra props go here
  };

const GeneralSetting: React.FC<Props> = ({selected}) => {
    const navigate = useNavigate();
    const [view, setView] = useState(1);

    const handleViewChange = () => {
        setView(view === 1? 2 : 1);
    };

    return (
        <div className="general_setting_container">
            { (selected)  && <Row>
                <Col span={2} />
                <Col span={20}>
                    <Row>
                        <Col span={24} className="heading_container">
                            {view === 1 && 
                                <ArrowLeftOutlined onClick={() => navigate(ROUTES.home)} style={{fontSize: "2.5rem", cursor: "pointer"}} />
                            }&nbsp;&nbsp;&nbsp;
                            <h1>General Settings</h1>
                        </Col>
                        <Divider style={{margin: "5px"}} />
                        <Col span={24}>
                            <label style={{fontSize: "1rem", fontWeight: "500", color: "#000"}}>Organization : {selected?.org}</label>
                        </Col>
                        <Col span={14} style={{marginTop: "1rem", display: "flex", justifyContent: 'space-between'}} >
                            <label style={{fontSize: "1.5rem", fontWeight: "500", color: "#000"}}>Species&nbsp;:</label>
                            { view === 1 ? <Species onViewChange={handleViewChange}  /> : <EditSpecies onViewChange={handleViewChange} /> }
                        </Col>
                    </Row>
                </Col>
                <Col span={2} />
            </Row>}
            
        </div>
)};

export default connector(GeneralSetting);