import { Dispatch } from "redux";
import {
  SendRequestCallback,
  CreateNotificationAction,
  Organization,
  GetOrganizationAction,
} from "../../types";
import { displayErrorNotification } from "../notifications";
import { GET_ORGANIZATION } from "../const";

export const getOrganization =
  (sendRequest: SendRequestCallback<undefined, undefined, Organization>) =>
  async (
    dispatch: Dispatch<GetOrganizationAction | CreateNotificationAction>
  ) => {
    try {
      const res = await sendRequest("GET")({
        url: `/organization`,
      });
      if (res?.status === 200) {
        dispatch({
          type: GET_ORGANIZATION,
          payload: res.data,
        });
      } else {
        throw Error("Status != 200");
      }
    } catch (error) {
      await displayErrorNotification("Failed to get organization")(dispatch);
    }
  };
