import React, { useEffect, useState } from 'react';
import { Button, Spin, Input } from 'antd';
import { Device } from '../../types';
import { LoadingOutlined, EditOutlined } from '@ant-design/icons';
import { ConnectedProps, connect } from 'react-redux';
import { displayNameUpdate } from '../../actions';
import { ReducerState } from '../../types';
import { useWebServer } from '../../providers';

const indicator = <LoadingOutlined style={{ fontSize: 15 }} spin />

const mapStateToProps = (state: ReducerState) => {
  return {
    count: state.devices?.count,
    devices: state.devices?.devices || {},
    selected: state.devices?.selected, 
  }
};

const mapDispatchToProps = {
  displayNameUpdate,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Index: React.FC<PropsFromRedux> = ({ selected, displayNameUpdate }) => {
  const { sendRequest, isLoading } = useWebServer();
  const [ loading, setLoading ] = useState(false);
  const [ enable, setEnable ] = useState(false);
  const [ display_name, setDeviceName ] = useState(selected?.display_name);

  const handleChangeName = (e: any) => {
    if(e.target.value){
      setDeviceName(e.target.value);
    }
  }
  

  const onClickUpdate = async () => {
    if(selected?.uuid){
      setLoading(true);
      let deviceUuid = selected?.uuid;
      await displayNameUpdate(sendRequest, deviceUuid, display_name);
      setLoading(false);
      setEnable(false);
    }
    
  }

  return (
      <Spin spinning={loading} indicator={indicator}>
        <label style={{fontSize: "1rem", fontWeight: "500", color: "#000"}}>Device : </label>
            <Input
                placeholder='Device'
                style={{width: "10rem"}}
                value={display_name}
                onChange={handleChangeName}
                disabled={!enable}
            />&nbsp;&nbsp;
            {!enable ? 
                <EditOutlined style={{fontSize: "1.5rem", cursor: "pointer"}} onClick={() => setEnable(true)} /> : 
                <Button type="primary" onClick={onClickUpdate}>SAVE</Button>
            }
      </Spin>
  );
};

export default connector(Index);
