import React from 'react'
import { Alert as AlertAntd, Col, Row, Tag} from 'antd';
import { Alert } from '../../../types';
import { TagColorMap } from './const';
import './index.css'


type Props = {
    item: Alert
    onClick: (id: string) => void
    selected: string | null
};

const AlertDialog: React.FC<Props> = ({item, selected, onClick}) => {
    const alertType = item?.severity === "danger" ? "error" : (item?.severity === "info" ? "info" : "warning");
    const getDateTime = (createdAt: string) => {
        if (createdAt) {
            const d = new Date(createdAt + ((createdAt.endsWith('+00:00') || createdAt.toLowerCase().endsWith('z')) ? "" : "+00:00"));
            const dateString = d.toLocaleString().replace(',', ' -');
            return dateString;
        }
        return '';      
    }
    const message = (item?.message || "").replace(/^\[.+\]/, '');

    const renderTag = () => {
        const tag = (item?.message || "").match(/^\[([a-zA-Z]+)\]/)?.[1];
        if (tag) {
            return (<Tag color={TagColorMap?.[tag] || "volcano"}>{tag}</Tag>);
        }
        
        return <></>
    }

    return (
        <div key={item?._id} className="alert-dialog-container">
            <AlertAntd 
                onClick={() => onClick(item?._id)}
                message={
                    <Row align={"middle"}>
                        <Col sm={24} md={24} lg={14} xl={14} >
                            <Row >
                                {message}
                            </Row>
                            <Row >{renderTag()}</Row>
                        </Col>
                        <Col sm={24} md={24} lg={10} xl={10}>
                            <Row justify={"end"}>
                                <Tag bordered={false}>{getDateTime(item?.created_at)}</Tag>
                            </Row>
                            {!selected? 
                                <Row justify={"end"} style={{marginTop: "3px"}}>
                                    <Tag bordered={false} color="#6082B6">{item?.device}</Tag> 
                                </Row>
                            : null}
                        </Col>
                    </Row>
                }
                type={alertType}
                showIcon 
            />
        </div>
    )
};


export default AlertDialog;

