import { Dispatch } from "redux";
import {
  SendRequestCallback,
  CreateNotificationAction,
  Analytics,
  GetDeviceAnalyticsAction,
} from "../../types";
import { displayErrorNotification } from "../notifications";
import { GET_DEVICE_ANALYTICS } from "../const";

export const getAnalytics =
  (
    sendRequest: SendRequestCallback<undefined, undefined, Analytics>,
    deviceUuid: string
  ) =>
  async (
    dispatch: Dispatch<GetDeviceAnalyticsAction | CreateNotificationAction>
  ) => {
    try {
      const res = await sendRequest("GET")({
        url: `/device/${deviceUuid}/analytics`,
      });
      if (res?.status === 200) {
        dispatch({
          type: GET_DEVICE_ANALYTICS,
          payload: { ...res.data, deviceUuid },
        });
      } else {
        throw Error("Status != 200");
      }
    } catch (error) {
      await displayErrorNotification("Failed to get analytics")(dispatch);
    }
  };
