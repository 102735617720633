import React, { useState, useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';
import { PlusOutlined } from '@ant-design/icons';
import { useWebServer } from '../../../providers';
import { message, Row, Col, Spin } from 'antd';
import { getScheduleCalendar } from '../../../utils';
import { ReducerState, ScheduleCalendar } from '../../../types';
import { CreateDeviceSchedule, GetNextDeviceSchedule, ListDeviceSchedules } from '../../../actions';
import { ScheduleComponent } from './Schedule';
import { SyncOutlined } from '@ant-design/icons';
import _ from 'lodash';

const mapStateToProps = (state: ReducerState) => {
    const lastCapStr = _.orderBy(state.captures.captures, 'captured_at', 'desc')?.[0]?.captured_at || "0+00:00";
    return {
        selected: state.devices?.selected?.uuid || null,
        currentSched: state.devices?.selected?.schedule_id || null,
        nextWakeupDate: state?.devices?.selected?.schedule || null,
        deviceUuid: state.schedules.deviceUuid,
        lastCap: new Date(lastCapStr + (lastCapStr.endsWith('+00:00') ? "" : "+00:00")),
        count: state.schedules.count,
        data: state.schedules.schedules,
    }
}

const connector = connect(mapStateToProps, {
    ListDeviceSchedules,
    GetNextDeviceSchedule,
    CreateDeviceSchedule,
});

type Props = ConnectedProps<typeof connector> & {
    // Extra props go here
};

const Index: React.FC<Props> = ({selected, deviceUuid, currentSched, nextWakeupDate, count, data, lastCap, ListDeviceSchedules, GetNextDeviceSchedule, CreateDeviceSchedule}) => {
    const navigate = useNavigate();
    const { sendRequest, isLoading } = useWebServer();
    
    const [calendarData, setCalendarData] = useState<ScheduleCalendar | null>(null);
    const [loading, setLoading] = useState(false);
    

    useEffect(() => {
        if (selected !== deviceUuid) {
            if (!isLoading && selected && selected !== deviceUuid) {
                ListDeviceSchedules(sendRequest, selected);
            }
        }
    }, [isLoading, selected, deviceUuid, sendRequest, ListDeviceSchedules])

    useEffect(() => {
        const cal = getScheduleCalendar(Object.values(data), new Date(nextWakeupDate || 0), lastCap, currentSched);
        setCalendarData(cal);
      
    }, [data, nextWakeupDate, lastCap, currentSched]);

    const create = () => {
        if(selected){
            navigate(ROUTES.schedule + '?c=1');
        } else {
            message.error("Please select a device first!");
        }
    }

    

    const navigateScreen = () => {
        if(selected){
            navigate(ROUTES.schedule);
        } else {
            message.error("Please select a device first!");
        }
    }

    const refreshSchedules = async() => {
        if(selected){
            setLoading(true);
            await ListDeviceSchedules(sendRequest, selected);
            setLoading(false)
        }
        
    }

    return (
        <Spin spinning={loading}>
            <div className="component_box_container" style={{cursor: "pointer", minWidth: "500px"}}>
                <Row justify="start" gutter={8} align="middle">
                <Col flex='auto'/>
                        <Col span={6}><h2 style={{margin: "0px"}}>Schedule</h2></Col>
        
                        <Col span={2}>
                            <PlusOutlined onClick={create} style={{ fontSize: "1rem", zIndex: 10 }} />
                        </Col>
                        <Col span={4}>
                            <SyncOutlined onClick={refreshSchedules} style={{ fontSize: "1rem", zIndex: 10 }} />
                        </Col>
                        <Col flex='auto'/>
                        <Col span={"auto"}>
                            <ScheduleComponent data={calendarData} rawData={data} nextWakeupDate={new Date(nextWakeupDate || 0)} onClick={navigateScreen}/>
                        </Col>
                    </Row>
            </div>
        </Spin>
    )
};

export default connector(Index);
