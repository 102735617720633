import { Dispatch } from "redux";
import {
  DELETE_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  CLEAR_ERRORS,
  CREATE_NOTIFICATION,
} from "../const";
import {
  ClearErrorsAction,
  ClearNotificationAction,
  CreateNotificationAction,
  DeleteNotificationAction,
} from "../../types";

export const displaySuccessNotification =
  (message: string) => async (dispatch: Dispatch<CreateNotificationAction>) =>
    dispatch({
      type: CREATE_NOTIFICATION,
      payload: {
        id: Date.now(),
        message,
        severity: "success",
      },
    });

export const displayInfoNotification =
  (message: string) => async (dispatch: Dispatch<CreateNotificationAction>) =>
    dispatch({
      type: CREATE_NOTIFICATION,
      payload: {
        id: Date.now(),
        message,
        severity: "info",
      },
    });

export const displayWarningNotification =
  (message: string) => async (dispatch: Dispatch<CreateNotificationAction>) =>
    dispatch({
      type: CREATE_NOTIFICATION,
      payload: {
        id: Date.now(),
        message,
        severity: "warning",
      },
    });

export const displayErrorNotification =
  (message: string) => async (dispatch: Dispatch<CreateNotificationAction>) =>
    dispatch({
      type: CREATE_NOTIFICATION,
      payload: {
        id: Date.now(),
        message,
        severity: "error",
        anchor: { vertical: "top", horizontal: "center" },
      },
    });

export const displayPermanentErrorNotification =
  (message: string) => async (dispatch: Dispatch<CreateNotificationAction>) =>
    dispatch({
      type: CREATE_NOTIFICATION,
      payload: {
        id: Date.now(),
        message,
        severity: "error",
        autoHideDuration: null,
        anchor: { vertical: "top", horizontal: "center" },
      },
    });

export const displayPermanentWarningNotification =
  (message: string, id: number) =>
  async (dispatch: Dispatch<CreateNotificationAction>) =>
    dispatch({
      type: CREATE_NOTIFICATION,
      payload: {
        id,
        message,
        severity: "warning",
        autoHideDuration: null,
        anchor: { vertical: "bottom", horizontal: "center" },
      },
    });

export const closeNotification =
  (id: number) => async (dispatch: Dispatch<DeleteNotificationAction>) =>
    dispatch({ type: DELETE_NOTIFICATION, payload: id });

export const clearNotifications =
  () => async (dispatch: Dispatch<ClearNotificationAction>) =>
    dispatch({ type: CLEAR_NOTIFICATIONS });

export const clearErrors =
  () => async (dispatch: Dispatch<ClearErrorsAction>) =>
    dispatch({ type: CLEAR_ERRORS });
