import React, {useEffect} from 'react'
import { ConnectedProps, connect } from 'react-redux';
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import deviceIconSvg from '../../../assets/icons/deviceIconSvg.svg'
import { ReducerState } from '../../../types';
import { getOrganization } from '../../../actions';
import { LatLngTuple, Icon } from 'leaflet';
import { useWebServer } from '../../../providers';
import { Spin } from 'antd';
import './index.css';

const mapStateToProps = (state: ReducerState) => {
  const map_center = state.devices?.selected?.gps || state.organizations?.map_center || {lat: 32.7361613, lon: -98.2829359};
  return {
    uuid: state.organizations?.uuid || null,
    map_center: [map_center?.lat, map_center?.lon] as LatLngTuple,
    address: state.organizations?.address || "",
    isDeviceLoc: !!state.devices?.selected?.gps
  }
}

const connector = connect(mapStateToProps, {
  getOrganization
});

type Props = ConnectedProps<typeof connector> & {
  // Extra props go here
};

const MapComponent: React.FC<Props> = ({map_center, uuid, address, isDeviceLoc, getOrganization }) => {
  const { sendRequest, isLoading } = useWebServer();

const deviceIcon = new Icon({
  iconUrl: deviceIconSvg,
  iconAnchor: [15, 30],
  className: 'leaflet-Icon'
})

  useEffect(() => {
    if (!isLoading && !uuid) {
      getOrganization(sendRequest)
    }
}, [getOrganization, sendRequest, isLoading, uuid])

  return (
       <div className="component_box_container">
    {(uuid && !isLoading) ? (
     
          <MapContainer
              worldCopyJump={true}
              center={map_center}
              minZoom={3}
              maxZoom={18}
              zoom={isDeviceLoc ? 18 : 5}
              scrollWheelZoom={true}
              style={{ width: "100%", height: "16.5rem" }}
          >
              <>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">Google Map</a> contributors'
                  url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {isDeviceLoc ? (
                  <Marker
                          position={map_center}
                          key={'map-center'}
                          icon={deviceIcon}
                        >
                          <Popup maxWidth={300} keepInView={false} autoClose={true}>
                          Map Center<br />
                          {`${map_center[0]?.toFixed(5) || 0}`}<br />
                          {`${map_center[1]?.toFixed(5) || 0}`}
                          </Popup>
                        </Marker>
                ) : null}
                        
              </>
          </MapContainer>
  )
  : (<Spin size="large"/>
          )
  }
  </div>
  )
};

export default connector(MapComponent);