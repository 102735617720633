import { combineReducers } from "redux";
import notificationsReducer from "./notifications";
import alertsReducer from "./alerts";
import capturesReducer from "./captures";
import devicesReducer from "./devices";
import organizationsReducer from "./organizations";
import schedulesReducer from "./schedules";
import analyticsReducer from "./analytics";
import commandReducer from "./command";

export default combineReducers({
  notifications: notificationsReducer,
  alerts: alertsReducer,
  captures: capturesReducer,
  devices: devicesReducer,
  organizations: organizationsReducer,
  schedules: schedulesReducer,
  analytics: analyticsReducer,
  command: commandReducer,
});
