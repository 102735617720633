import React from 'react';
import {  Row, Col, Tooltip } from 'antd';
import { Day, Schedule, ScheduleBlock, ScheduleCalendar } from '../../../types';
import { DAYS_OF_THE_WEEK, HOURS, WEEKS, getCurrWeek } from '../../../utils';
import _ from 'lodash';
import './schedule.css'

type Props = {
  data: ScheduleCalendar | null
  rawData: { [key: string]: Schedule },
  nextWakeupDate: Date,
  onClick: React.MouseEventHandler<HTMLInputElement>
};

export const ScheduleComponent: React.FC<Props> = ({data, rawData, nextWakeupDate, onClick}) => {
  const renderGrid = () => {
    const now = new Date();
    const nowHour = now.getHours()
    const nowPerc = (now.getMinutes() * 60 + now.getSeconds()) / HOURS;
    const DEFAULT_END = 1 * WEEKS + now.getTimezoneOffset() * 60;
    const nextWakeup = getCurrWeek(nextWakeupDate);
    const currWeekNow = getCurrWeek(now);
    const isWakeupNextWeek = nextWakeup < currWeekNow;

    if (!data) return null;
    return [
      (
        <Row key={'col-headers'} gutter={0} wrap={false}>
          {
          [(<Col key={`header-axis`} span={3}>
          <div className="schedule-row-header">
            
          </div>
        </Col>), ..._.range(0,24).map((c) =>
          (<Col key={`header-${c}`} span={1}>
          <div className="schedule-header">
            {`${c === 0 ? '12 AM' : (c < 12 ? `${c} AM` : (c === 12 ? '12 PM' : `${c - 12} PM`))}`}
          </div>
        </Col>))]
        }
        </Row>
      ),
      ...DAYS_OF_THE_WEEK.map((day: Day, r) => {
        let currentDay = now.getDay();
        currentDay = currentDay === 0 ? 6 : currentDay - 1;
        const isNowDay = currentDay === r;
        
        const rowData = data[day];
        const cols = [
          (
            <Col key={`header-${day}`} span={3}>
              <div className="schedule-row-header">
                {day}
              </div>
            </Col>
          ),
          ..._.range(0,24).map((c) => {
            
          const colData: ScheduleBlock | null = ((rowData as any)?.[`time${c}`]) || null;
          let color = 'white';
          let tooltip = '';
          if (colData) {
            color = colData?.activelyScheduled ? "#00810E" :  "#F7EC03";
            if (!colData?.activelyScheduled) {
              tooltip = `${colData?.display_name} Pending`
            }
            else if (colData?._id in rawData) {
              const sched = rawData[colData?._id] || {};
              // console.log((sched as any)?.display_name || `Job ${sched?._id?.substring?.(0, 6) || "0"}`);
              const isNextWeek = (sched.start < currWeekNow) && ((sched?.end || DEFAULT_END) < currWeekNow);
              // console.log(`isWakeupNextWeek (${isWakeupNextWeek}) = nextWakeup (${nextWakeup}) < currWeekNow (${currWeekNow})`);
              const num_cap_start = (isWakeupNextWeek || isNextWeek) ? sched.start : (_.max([sched.start, nextWakeup]) || sched.start);
              // console.log(`isWakeupNextWeek: ${isWakeupNextWeek}, num_cap_start: ${num_cap_start}, sched_start: ${sched.start}, nextWakeup: ${nextWakeup}, sched_end: ${sched?.end || DEFAULT_END}, sched_period: ${sched.period}, num_captures: ${_.floor(((sched?.end || DEFAULT_END) - num_cap_start)/(sched.period + 5))}`)
              tooltip = `Job ${colData.display_name}: ${_.max([_.floor(((sched?.end || DEFAULT_END) - num_cap_start)/(sched.period + 5)), 1])} Captures`;
            }
            
          }
          const fillerStyle = { backgroundColor: colData?.isMidInflection ? "#00810E" : color, width: `${_.ceil((colData?.percentage || 1) * 30)}px` }
          const emptyStyle = { backgroundColor: colData?.isMidInflection ? "#F7EC03" : '#FFFFFF', width: `${_.floor((1 - (colData?.percentage || 1)) * 30)}px` }
          const wrapperStyle = ('isEndBlock' in (colData || {}) && 'isStartBlock' in (colData || {})) ? {
            ...(!(colData?.isEndBlock || c === 23) ? {borderRight: '0px'} : {}),
            ...(!(colData?.isStartBlock || c === 0) ? {borderLeft: '0px'} : {}),
            ...(!(colData?.isMidInflection) ? {borderRight: '0px', borderLeft: '0px'} : {}),
            ...(((colData?.percentage || 1) === 1) ? {backgroundColor: color, borderRight: '0px', borderLeft: '0px'} : {})
          } : {};
          if (isNowDay && nowHour === c) {
            console.log(`Found filler-now location: ${isNowDay} ${nowHour} ${nowPerc}`);
          }
          return (
          <Tooltip title={tooltip} key={`${r}-${c}`}>
            <Col span={1} className="schedule-item" style={wrapperStyle}>
              {
                ((colData?.percentage || 1) !== 1) ? <>
                <div className="schedule-item-filler-1" style={colData?.isStartBlock ? emptyStyle : fillerStyle}></div>
                
              <div className="schedule-item-filler-2" style={colData?.isStartBlock ? fillerStyle : emptyStyle }></div>
              </> : <></>
              }

              {
                (isNowDay && nowHour === c) ? (
                  <Tooltip title={"Current Time"} key={`now-tooltip`} style={{width: "4px"}}>
                  <div className="schedule-item-filler-now" style={{left: `${nowPerc * 100}%`}}></div>
                   </Tooltip>
                ) : null
              }
              
            </Col>
          </Tooltip>
        )})];
        return (
          <Row key={r} gutter={[0, 0]} wrap={false}>
            {cols}
          </Row>
        )
    })]
  };

  return (
    <div style={{ padding: '20px' }} onClick={onClick}>
      {renderGrid()}
    </div>
  );
};