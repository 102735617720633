import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Spin, Row, Col, Flex, Button, Popconfirm, Tag, Popover, Checkbox } from 'antd';
import { ConnectedProps, connect } from 'react-redux';
import { useWebServer } from '../../../providers';
import { clearAlerts, listAlerts, updateAlerts } from '../../../actions';
import { ReducerState, Alert } from '../../../types';
import AlertDialog from './AlertDialog';
import { TagColorMap } from './const';
import { SyncOutlined, FunnelPlotOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { debounce } from 'lodash';

const CheckboxGroup = Checkbox.Group;

const plainOptions = ['Info', 'Danger', 'Warning'];
const defaultCheckedList = ['Info', 'Danger', 'Warning'];

const mapStateToProps = (state: ReducerState) => {
    const alerts = state.alerts;
    return {
        selected: state.devices?.selected?.uuid || null,
        alerts: Object.values(alerts.alerts),
        count: alerts.count,
        deviceUuid: alerts.deviceUuid,
        currentPage: alerts.page,
        currentPageSize: alerts.pageSize,
        tagValue: alerts.tag,
    }
}

const connector = connect(mapStateToProps, {
    listAlerts,
    updateAlerts,
    clearAlerts
});

type Props = ConnectedProps<typeof connector> & {
    // Extra props go here
};

const MaintenanceAlert: React.FC<Props> = ({ alerts, count, deviceUuid, selected, currentPage, currentPageSize, tagValue, listAlerts, updateAlerts, clearAlerts }) => {
    const { sendRequest, isLoading } = useWebServer();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [selectedTags, setSelectedTags] = useState<string[]>(Object.keys(TagColorMap));
    const [loading, setLoading] = useState(false);
    const [popHoverVisible, setPopHoverVisible] = useState(false);
    const listContainerRef = useRef<HTMLDivElement>(null);
    const [selectedLevels, setSelectedLevels] = useState<string[]>(defaultCheckedList);

    const fetchAlerts = useCallback(async () => {
        setLoading(true);
        await listAlerts(sendRequest, { page, pageSize, device: selected || undefined, tags: selectedTags, levels: selectedLevels });
        setLoading(false);
    }, [listAlerts, sendRequest, page, pageSize, selected, selectedTags, selectedLevels]);

    useEffect(() => {
        fetchAlerts();
    }, [fetchAlerts]);

    const handleScroll = useCallback(debounce(() => {
        if (listContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listContainerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 5 && !loading) {
                if(count >= pageSize){
                    setPageSize(prevPageSize => prevPageSize + 10);
                }
            }
        }
    }, 200), [loading]);

    useEffect(() => {
        const listContainer = listContainerRef.current;
        if (listContainer) {
            listContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (listContainer) {
                listContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);

    const onClickRefresh = async () => {
        setLoading(true);
        setPage(0);
        setPageSize(10);
        await listAlerts(sendRequest, { page: 0, pageSize, device: selected || undefined, tags: selectedTags, levels: selectedLevels });
        setLoading(false);
    };

    const doUpdateAlert = async (id: string) => {
        setLoading(true);
        await updateAlerts(sendRequest, id);
        setLoading(false);
    };

    const handleChangeSelectedTags = (tag: string, checked: boolean) => {
        if (checked) {
            setSelectedTags([...selectedTags, tag]);
        } else {
            setSelectedTags(_.without(selectedTags, tag));
        }
    };

    const onClickClearAll = async () => {
        if (selected) {
            setLoading(true);
            await clearAlerts(sendRequest, selected, { tags: selectedTags });
            onClickRefresh();
            setLoading(false);
        }
    };

    const handleOpenChange = (data: boolean) => {
        setPopHoverVisible(data);
    };

    const handleChangeAlertFilters = (checkedValue: any[]) => {
        setSelectedLevels(checkedValue);
    }

    return (
        <div className="component_box_container">
            <Spin spinning={loading}>
                <Row className="alert-header-container" justify={'space-between'} align={'middle'}>
                    <Col span={14}><h2>Maintenance Alerts</h2></Col>
                    <Col span={6}>
                        {selected && (
                            <Popconfirm title={"Are you sure?"} okText="Yes" onConfirm={onClickClearAll}>
                                <Button type="primary" danger ghost>Clear All</Button>
                            </Popconfirm>
                        )}
                    </Col>
                    <Col span={2}>
                        <SyncOutlined style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={onClickRefresh} />
                    </Col>
                    <Col span={2}>
                    <Popover
                        content={ <CheckboxGroup options={plainOptions} value={selectedLevels} onChange={handleChangeAlertFilters}/> }
                        title=""
                        placement="bottomRight"
                        trigger="click"
                        open={popHoverVisible}
                        onOpenChange={handleOpenChange}
                        style={{width: "50px"}}
                    >
                        <FunnelPlotOutlined style={{ fontSize: "1.5rem", cursor: "pointer" }} />
                    </Popover>
                    </Col>
                    <Col span={24}>
                        <Flex gap={0} wrap={"wrap"} align="right">
                            {Object.entries(TagColorMap).map<React.ReactNode>(([tag, color]) => (
                                <Tag.CheckableTag
                                    key={`tag-${tag}`}
                                    checked={selectedTags.includes(tag)}
                                    onChange={(checked) => handleChangeSelectedTags(tag, checked)}
                                    style={{ borderColor: 'black' }}
                                >
                                    {tag}
                                </Tag.CheckableTag>
                            ))}
                        </Flex>
                    </Col>
                </Row>
                <div ref={listContainerRef} style={{ overflow: "scroll", height: "190px" }}>
                    {alerts.map((item: Alert) => (
                        <AlertDialog
                            key={item._id}
                            item={item}
                            onClick={doUpdateAlert}
                            selected={selected}
                        />
                    ))}
                </div>
            </Spin>
        </div>
    );
};

export default connector(MaintenanceAlert);
