import { Dispatch } from "redux";
import {
  SendRequestCallback,
  CreateNotificationAction,
  Schedule,
  GetNextDeviceScheduleAction,
  ListDeviceSchedulesAction,
  listSchedulesResponse,
  CreateDeviceScheduleAction,
  createScheduleRequest,
  createResponse,
  updateScheduleRequest,
  updateResponse,
  UpdateDeviceScheduleAction,
  deleteResponse,
  DeleteDeviceScheduleAction,
} from "../../types";
import { displayErrorNotification } from "../notifications";
import {
  CREATE_DEVICE_SCHEDULE,
  DELETE_DEVICE_SCHEDULE,
  GET_NEXT_DEVICE_SCHEDULE,
  LIST_DEVICE_SCHEDULES,
  UPDATE_DEVICE_SCHEDULE,
} from "../const";

export const GetNextDeviceSchedule =
  (
    sendRequest: SendRequestCallback<undefined, undefined, Schedule>,
    deviceUuid: string
  ) =>
  async (
    dispatch: Dispatch<GetNextDeviceScheduleAction | CreateNotificationAction>
  ) => {
    try {
      const res = await sendRequest("GET")({
        url: `/device/${deviceUuid}/schedule`,
      });
      if (res?.status === 200) {
        dispatch({
          type: GET_NEXT_DEVICE_SCHEDULE,
          payload: { ...res.data, deviceUuid },
        });
      } else {
        throw Error("Status != 200");
      }
    } catch (error) {
      await displayErrorNotification("Failed to get next schedule")(dispatch);
    }
  };

export const ListDeviceSchedules =
  (
    sendRequest: SendRequestCallback<
      undefined,
      undefined,
      listSchedulesResponse
    >,
    deviceUuid: string
  ) =>
  async (
    dispatch: Dispatch<ListDeviceSchedulesAction | CreateNotificationAction>
  ) => {
    try {
      const res = await sendRequest("GET")({
        url: `/device/${deviceUuid}/schedules`,
      });
      if (res?.status === 200) {
        dispatch({
          type: LIST_DEVICE_SCHEDULES,
          payload: { ...res.data, deviceUuid },
        });
      } else {
        throw Error("Status != 200");
      }
    } catch (error) {
      await displayErrorNotification("Failed to list schedules")(dispatch);
    }
  };

export const CreateDeviceSchedule =
  (
    sendRequest: SendRequestCallback<
      createScheduleRequest,
      undefined,
      createResponse
    >,
    deviceUuid: string,
    data: createScheduleRequest
  ) =>
  async (
    dispatch: Dispatch<CreateDeviceScheduleAction | CreateNotificationAction>
  ) => {
    try {
      const res = await sendRequest("POST")({
        url: `/device/${deviceUuid}/schedule`,
        data,
      });
      if (res?.status === 200 || res?.status === 201) {
        dispatch({
          type: CREATE_DEVICE_SCHEDULE,
          payload: { scheduleUuid: res.data._id, deviceUuid, ...data },
        });
      } else {
        throw Error("Status != 200");
      }
    } catch (error) {
      await displayErrorNotification("Failed to create schedule")(dispatch);
    }
  };

export const UpdateDeviceSchedule =
  (
    sendRequest: SendRequestCallback<
      updateScheduleRequest,
      undefined,
      updateResponse
    >,
    deviceUuid: string,
    scheduleUuid: string,
    data: updateScheduleRequest
  ) =>
  async (
    dispatch: Dispatch<UpdateDeviceScheduleAction | CreateNotificationAction>
  ) => {
    try {
      const res = await sendRequest("PATCH")({
        url: `/device/${deviceUuid}/schedule/${scheduleUuid}`,
        data,
      });
      if (res?.status === 200 && res.data.success) {
        dispatch({
          type: UPDATE_DEVICE_SCHEDULE,
          payload: { deviceUuid, scheduleUuid, ...data },
        });
      } else {
        throw Error("Status != 200");
      }
    } catch (error) {
      await displayErrorNotification("Failed to update schedule")(dispatch);
    }
  };

export const DeleteDeviceSchedule =
  (
    sendRequest: SendRequestCallback<undefined, undefined, deleteResponse>,
    deviceUuid: string,
    scheduleUuid: string
  ) =>
  async (
    dispatch: Dispatch<DeleteDeviceScheduleAction | CreateNotificationAction>
  ) => {
    try {
      const res = await sendRequest("DELETE")({
        url: `/device/${deviceUuid}/schedule/${scheduleUuid}`,
      });
      if (res?.status === 200 && res.data.success) {
        dispatch({
          type: DELETE_DEVICE_SCHEDULE,
          payload: { deviceUuid, scheduleUuid },
        });
      } else {
        throw Error("Status != 200");
      }
    } catch (error) {
      await displayErrorNotification("Failed to delete schedule")(dispatch);
    }
  };
