import _ from "lodash";
import { Day, Schedule, ScheduleCalendar } from "../types";
import { DAYS, DAYS_OF_THE_WEEK, HOURS, MINUTES, WEEKS } from "./const";
import { Dayjs } from "dayjs";

export const timeAndWeekdayToSeconds = (
  timeString: string,
  weekday: string
) => {
  const week = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const date = new Date();
  const [time] = timeString.split(" ");
  const [hours, minutes] = time.split(":").map(Number);

  let targetHours = hours;

  // Set the target time
  date.setHours(targetHours);
  date.setMinutes(minutes);
  date.setSeconds(0);

  let foundIndex = week?.findIndex((item) => item === weekday);

  // Get the current time in seconds
  const currentTimeInSeconds =
    date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();

  return 24 * 60 * 60 * foundIndex + currentTimeInSeconds;
};

export const secondsToTimeAndWeekday = (seconds: number) => {
  const week = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const secondsInDay = 24 * 60 * 60;
  const weekIndex = Math.floor(seconds / secondsInDay) % 7;
  const remainingSeconds = seconds % secondsInDay;
  const hours = Math.floor(remainingSeconds / 3600);
  const minutes = Math.floor((remainingSeconds % 3600) / 60);

  const formattedHours = hours;

  const timeString = `${formattedHours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;

  const weekday = week[weekIndex];

  return { time: timeString, weekday };
};

export const secondsToTimeAndWeekdayAP = (seconds: number) => {
  const week = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const secondsInDay = 24 * 60 * 60;
  const weekIndex = Math.floor(seconds / secondsInDay) % 7;
  const remainingSeconds = seconds % secondsInDay;
  const hours = Math.floor(remainingSeconds / 3600);
  const minutes = Math.floor((remainingSeconds % 3600) / 60);
  const meridiem = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const timeString = `${formattedHours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")} ${meridiem}`;

  const weekday = week[weekIndex];

  return { time: timeString, weekday };
};

// helper: get number of seconds since start of the week
export const getCurrWeek = (now: Date): number => {
  let currentDay = now.getUTCDay();
  currentDay = (currentDay + 6) % 7; // Adjust current day to make Monday the start of the week
  const millisecondsSinceStartOfWeek =
    currentDay * 24 * 60 * 60 * 1000 +
    now.getUTCHours() * 60 * 60 * 1000 +
    now.getUTCMinutes() * 60 * 1000 +
    now.getUTCSeconds() * 1000 +
    now.getUTCMilliseconds();
  const secondsSinceStartOfWeek = Math.floor(
    millisecondsSinceStartOfWeek / 1000
  );
  return secondsSinceStartOfWeek;
};

const getWeekNumber = (day: Day) => {
  return DAYS_OF_THE_WEEK.findIndex((item) => item === day);
};

export const getCurrWeekByDayTime = (day: Day, datetime: Dayjs): number => {
  const tzCurrWeek =
    getWeekNumber(day) * DAYS +
    datetime.hour() * HOURS +
    datetime.minute() * MINUTES +
    datetime.second();
  const utcCurrWeek = tzCurrWeek - datetime.utcOffset() * 60;
  if (utcCurrWeek < 0) {
    return utcCurrWeek + WEEKS;
  }
  return utcCurrWeek;
};

const getUTCMondayMidnightDate = (now: Date): Date => {
  const currentDay = now.getUTCDay(); // Get current day (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
  const mondayMidnight = new Date(now); // Create a new Date object with the current date

  // Calculate milliseconds to subtract to get to Monday
  const millisecondsInDay = 1000 * 60 * 60 * 24;
  const daysToMonday =
    currentDay === 1 ? 0 : currentDay === 0 ? 6 : currentDay - 1; // If today is Monday, don't subtract any days
  const millisecondsToMonday = daysToMonday * millisecondsInDay;

  // Set the time to Monday midnight
  mondayMidnight.setUTCHours(0, 0, 0, 0);
  // Subtract milliseconds to get to Monday midnight
  mondayMidnight.setTime(mondayMidnight.getTime() - millisecondsToMonday);
  return mondayMidnight;
};

const getUTCMondayMidnightTimestamp = (now: Date): number => {
  const mondayMidnight = getUTCMondayMidnightDate(now);

  // Return the UTC timestamp for Monday midnight of the current week
  return Math.floor(mondayMidnight.getTime() / 1000); // Convert milliseconds to seconds
};

// helper: get the next valid utc timestamp for currweek
export const fromCurrWeek = (currweek: number, now: Date): Date => {
  const nowcurrweek = getCurrWeek(now);
  let monday = getUTCMondayMidnightTimestamp(now);
  if (currweek < nowcurrweek) {
    monday += 1 * WEEKS;
  }
  const d = new Date((monday + currweek) * 1000);
  return d;
};

const DEFAULT_SCHEDULE: ScheduleCalendar = {
  Monday: {},
  Tuesday: {},
  Wednesday: {},
  Thursday: {},
  Friday: {},
  Saturday: {},
  Sunday: {},
};

const dayToString = (day: number): string => {
  switch (day) {
    case 0:
      return "Monday";
    case 1:
      return "Tuesday";
    case 2:
      return "Wednesday";
    case 3:
      return "Thursday";
    case 4:
      return "Friday";
    case 5:
      return "Saturday";
    case 6:
      return "Sunday";
    default:
      return "";
  }
};

export const getScheduleCalendar = (
  schedulesData: Schedule[],
  nextWakeupDate: Date,
  lastCap: Date,
  currentSchedId: string | null
): ScheduleCalendar => {
  const now = new Date();
  const nextWakeup = getCurrWeek(nextWakeupDate);
  const isCapturing =
    currentSchedId !== null &&
    currentSchedId !== "0" &&
    ((lastCap as any) - (now as any)) / 1000 <= 5 * MINUTES; // captured in the last 5 minutes
  console.log(`isCapturing: ${isCapturing}`);
  const isWakeupNextWeek = nextWakeup < getCurrWeek(now) && !isCapturing;
  console.log(`isWakeupNextWeek: ${isWakeupNextWeek}`);
  const DEFAULT_END = 1 * WEEKS + now.getTimezoneOffset() * 60 - 1;
  const mondayMidnight = getUTCMondayMidnightDate(now);
  const calendar = _.orderBy(schedulesData, ["start"], ["asc"]).reduce(
    (cal: ScheduleCalendar, schedule: Schedule | null) => {
      if (!schedule) {
        return cal;
      }
      const { start, device, enabled, end, ...rest } = schedule;

      if (enabled || (isCapturing && rest._id === currentSchedId)) {
        const startDate = fromCurrWeek(start, mondayMidnight);

        const startDay = (startDate.getDay() + 6) % 7;
        const startHour = startDate.getHours();
        const endDate = fromCurrWeek(schedule?.end || DEFAULT_END, startDate);
        const endDay = (endDate.getDay() + 6) % 7;
        const endHour = endDate.getHours();
        const startCalendarPart: Partial<ScheduleCalendar> = {
          [dayToString(startDay)]: {
            [`time${startHour}`]: {
              ...rest,
              isStartBlock: true,
              isEndBlock: false,
              percentage:
                1 -
                (startDate.getUTCMinutes() * MINUTES +
                  startDate.getUTCSeconds()) /
                  HOURS,
            },
          },
        };
        if (
          startDate.getDate() === endDate.getDate() &&
          startDay === endDay &&
          startHour === endHour
        ) {
          return _.defaultsDeep(
            {},
            cal,
            {
              [dayToString(startDay)]: {
                [`time${startHour}`]: {
                  percentage:
                    (endDate.getUTCMinutes() * MINUTES +
                      endDate.getUTCSeconds() -
                      (startDate.getUTCMinutes() * MINUTES +
                        startDate.getUTCSeconds())) /
                    HOURS,
                  isEndBlock: true,
                },
              },
            },
            startCalendarPart
          );
        } else {
          const numHours =
            (endDay - startDay + 1) * 24 - (24 - (endHour + 1)) - startHour - 2;
          // (endDay - startDay + 1) * 24 - (24 - endHour) - startHour - 1; // the number of full hours between start and end besides the start hour and the end hour
          let currDay = startDay;
          let currHour = startHour + 1;
          let activelyScheduled = schedule.activelyScheduled;
          const midBlocks: Partial<ScheduleCalendar> =
            numHours > 0
              ? _.range(_.floor(numHours)).reduce(
                  (o, i) => {
                    let ret = null;
                    if (!activelyScheduled && !isWakeupNextWeek) {
                      if (
                        nextWakeup >=
                          currDay * DAYS +
                            currHour * HOURS +
                            now.getTimezoneOffset() * 60 &&
                        nextWakeup <
                          currDay * DAYS +
                            (currHour + 1) * HOURS +
                            now.getTimezoneOffset() * 60
                      ) {
                        activelyScheduled = true;
                        ret = _.defaultsDeep(
                          {},
                          {
                            [dayToString(currDay)]: {
                              [`time${currHour}`]: {
                                ...rest,
                                activelyScheduled,
                                isStartBlock: true,
                                isEndBlock: false,
                                isMidInflection: true,
                                percentage:
                                  1 -
                                  (nextWakeupDate.getUTCMinutes() * MINUTES +
                                    nextWakeupDate.getUTCSeconds()) /
                                    HOURS,
                              },
                            },
                          },
                          o
                        );
                      }
                    }

                    if (!ret) {
                      ret = _.defaultsDeep(
                        {},
                        {
                          [dayToString(currDay)]: {
                            [`time${currHour}`]: {
                              ...rest,
                              activelyScheduled,
                              isStartBlock: false,
                              isEndBlock: false,
                              percentage: 1,
                            },
                          },
                        },
                        o
                      );
                    }

                    if (currHour === 23) {
                      currDay += 1;
                      currHour = 0;
                    } else {
                      currHour += 1;
                    }
                    return ret;
                  },
                  { ...DEFAULT_SCHEDULE }
                )
              : {};
          const endCalendarPart: Partial<ScheduleCalendar> = {
            [dayToString(endDay)]: {
              [`time${endHour}`]: {
                ...rest,
                isStartBlock: false,
                isEndBlock: true,
                activelyScheduled,
                percentage:
                  (endDate.getUTCMinutes() * MINUTES +
                    endDate.getUTCSeconds()) /
                  HOURS,
              },
            },
          };
          console.log(
            `startCalendarPart: ${JSON.stringify(startCalendarPart, null, 4)}`
          );
          console.log(`midBlocks: ${JSON.stringify(midBlocks, null, 4)}`);
          console.log(
            `endCalendarPart: ${JSON.stringify(endCalendarPart, null, 4)}`
          );
          return _.defaultsDeep(
            {},
            cal,
            startCalendarPart,
            midBlocks,
            endCalendarPart
          );
        }
      }
      return cal;
    },
    { ...DEFAULT_SCHEDULE }
  );
  return calendar;
};
