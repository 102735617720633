import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { PlusOutlined, MoreOutlined, EditFilled, DeleteFilled } from '@ant-design/icons';
import { Switch, Row, Col, List, Dropdown, Menu, Popconfirm, Tooltip  } from 'antd';
import { fromCurrWeek } from '../../utils';
import { ReducerState, Schedule, createScheduleRequest, updateScheduleRequest, } from '../../types';
import { CreateDeviceSchedule, DeleteDeviceSchedule, ListDeviceSchedules, UpdateDeviceSchedule } from '../../actions';
import { useWebServer } from '../../providers';
import './timer.css'
import { useSearchParams } from 'react-router-dom';
import CreateScheduleModal from '../DevicePanel/Schedule/createScheduleModal';
import _ from 'lodash';

const mapStateToProps = (state: ReducerState) => {
    return {
        selected: state.devices?.selected?.uuid || null,
        deviceUuid: state.captures.deviceUuid,
        count: state.schedules.count,
        data: Object.values(state.schedules.schedules),
    }
}

const connector = connect(mapStateToProps, {
    ListDeviceSchedules,
    UpdateDeviceSchedule,
    DeleteDeviceSchedule,
    CreateDeviceSchedule,
});

type Props = ConnectedProps<typeof connector> & {
    // Extra props go here
};
const Timer: React.FC<Props> = ({
    data, selected, deviceUuid, count, 
    ListDeviceSchedules,
    UpdateDeviceSchedule,
    DeleteDeviceSchedule,
    CreateDeviceSchedule,}) => {

    const { sendRequest, isLoading } = useWebServer();
    const [searchParams, ] = useSearchParams();
    const [selectedSchedule, setSelectedSchedule] = useState<Schedule | null>(null);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const handleMenuClick = (item: Schedule, key: string) => {
        if (key === '1') {
            if (item._id !== '0') {
                handleEditTimer(item);
            }
        }
    };

    const menu = (item: Schedule) => (
        <Tooltip title={item?._id === '0' ? "Currently Active" : ""} style={{width: "4px"}}>
        <Menu onClick={({ key }) => handleMenuClick(item, key)}>
            
            <Menu.Item key="1" icon={<EditFilled />} disabled={item?._id === '0'}>Edit</Menu.Item>
            
            <Menu.Divider />
            <Menu.Item key="2" icon={<DeleteFilled />} danger  disabled={item?._id === '0'}>
                <Popconfirm
                    title="Delete schedule"
                    description="Are you sure to delete this schedule?"
                    onConfirm={() => handleDelete(item._id)}
                    okText="Yes"
                    cancelText="No"
                >
                    Delete
                </Popconfirm>
            </Menu.Item>
            
        </Menu>
        </Tooltip>
    );
    
    useEffect(() => {
        if (searchParams.get('c') === '1') {
            handleCreateTimer();
        }
    }, [searchParams])

    useEffect(() => {
        if (selected !== deviceUuid) {
            if (!isLoading && selected && selected !== deviceUuid) {
                ListDeviceSchedules(sendRequest, selected);
            }
        }
    }, [isLoading, selected, deviceUuid, sendRequest, ListDeviceSchedules])

    const handleCreate = async (data: createScheduleRequest) => {
        if (selected) {
            setLoading(true);
            await CreateDeviceSchedule(sendRequest, selected, data);
            await ListDeviceSchedules(sendRequest, selected);
            setLoading(false);
            handleClose();
        }
    }

    const handleUpdate = async (_id: string, data: updateScheduleRequest ) => {
        if (selected) {
            setLoading(true);
            await UpdateDeviceSchedule(sendRequest, selected, _id, data);
            await ListDeviceSchedules(sendRequest, selected);
            setLoading(false);
            handleClose();
        }
    }

    const handleClose = () => {
        setModalOpen(false);
    }

    const handleDelete = async (_id: string) => {
        if (selected) {
            setLoading(true);
            await DeleteDeviceSchedule(sendRequest, selected, _id);
            await ListDeviceSchedules(sendRequest, selected);
            setLoading(false);
            handleClose();
        }
    }

    const handleEditTimer = async (data: Schedule) => {
        setSelectedSchedule(data);
        setModalOpen(true);
    }

    const handleCreateTimer = async () => {
        setSelectedSchedule(null);
        setModalOpen(true);
    }

    const handleToggleEnabled = (item: Schedule, checked: boolean) => {
        if (selected) {
            UpdateDeviceSchedule(sendRequest, selected, item._id, {enabled: checked})
        }
        
    }

    const displayDate = (d: Date) => {
        return (
        <span className="dateTimeText">
            {d.toDateString()}
            <br/>
            {d.toLocaleTimeString()}
        </span>)
    }

    return (
        <Row className="component_box_container" style={{width: "50%"}}>
            <CreateScheduleModal
                open={modalOpen}
                loading={loading}
                onCreate={handleCreate}
                onUpdate={handleUpdate}
                onDelete={handleDelete}
                onCancel={handleClose}
                initialData={selectedSchedule}
                />
            <Col className="timerContainer">
            <Row gutter={16} align="middle" justify={'center'} wrap={false}> 
                <Col flex="auto"/>
                <Col flex="auto"><h2 style={{margin: "0px"}}>Capture Jobs</h2></Col>
                <Col span={2}/>
                <Col span={6}>
                <div>
                    <PlusOutlined onClick={handleCreateTimer} style={{ fontSize: "1.8rem" }} />
                    <MoreOutlined style={{ fontSize: "1.8rem" }} />
                </div>
                </Col>
                <Col flex="auto"/>
            </Row>
            </Col>
            <br />
            <div className="component_box_container timerContainer">
                <Col className="timerContainer">
                <List
                    pagination={{position: "bottom", pageSize: 3}}
                    dataSource={_.orderBy(data, ['enabled', 'start', 'end'], ['desc', 'asc', 'asc'])}
                    renderItem={(item: Schedule, index: number) => (
                        item && <Row key={"timer_"+index} 
                            className={'timerRow'}
                            style={{borderBottom: index === data?.length - 1 ? "" : "1px solid darkgray"}}
                            gutter={16} align="middle" wrap={false} justify={"space-evenly"}
                        >
                            <Col className="timerContainer">
                                <Row gutter={16} align="middle" >
                                    <Col flex="auto">
                                    <Tooltip title={item?._id === '0' ? "Currently Active" : ""} style={{width: "4px"}}>
                                        <span className={item?._id === '0' ? "disabledTimerTitle" : "timerTitle"}>{item.display_name}</span>
                                    </Tooltip>
                                    </Col>
                                    <Col flex="auto">
                                    <Switch disabled={item?._id === '0'} checked={item?.enabled} onChange={(checked: boolean) => handleToggleEnabled(item, checked)}/>
                                    </Col>
                                    <Col flex="auto">
                                    <Dropdown overlay={menu(item)} trigger={['click']}>
                                        <a href="/#" onClick={(e) => e.preventDefault()}>
                                            <MoreOutlined style={{ fontSize: "1.8rem", cursor: "pointer" }} />
                                        </a>
                                    </Dropdown>
                                        
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col flex="auto">Start: </Col>
                                    <Col flex="auto">
                                        {displayDate(fromCurrWeek(item.start, new Date()))}
                                    </Col>
                                    <Col flex="auto"/>
                                </Row>
                                <br/>
                                <Row>
                                <Col flex="auto">{item?.end ? "End: " : null}</Col>
                                    <Col flex="auto">
                                    
                                    {item?.end ? 
                                            displayDate(fromCurrWeek(item?.end, new Date()))
                                        : null
                                    }
                                    </Col>
                                    <Col flex="auto"/>
                                </Row>
                                <br/>
                                <Row>
                                <Col flex="auto">Every: </Col>
                                    <Col flex="auto">
                                    
                                    {item?.period} seconds
                                    </Col>
                                    <Col flex="auto"/>
                                </Row>
                            </Col>
                        </Row>
                    )}
                />
            {/* {
                data?.map((item: Schedule, index: number) => (
                    
                    <Row key={"timer_"+index} 
                    className={'timerRow'}
                    style={{borderBottom: index === data.length - 1 ? "" : "1px solid darkgray"}}
                    gutter={16} align="middle" wrap={false} justify={"space-evenly"}
                    >
                        <Col className="timerContainer">
                            <Row gutter={16} align="middle" >
                                <Col flex="auto"/>
                                <Col flex="auto">
                                    <span className="timerTitle" onClick={() => handleEditTimer(item)}>{"Job " + (index + 1)}</span>
                                </Col>
                                <Col span={1}/>
                                <Col flex="auto">
                                <Switch disabled={item._id === '0'} checked={item.enabled} onChange={(checked: boolean) => handleToggleEnabled(item, checked)}/>
                                </Col>
                                <Col flex="auto"/>
                            </Row>
                            <br/>
                            <Row>
                                <Col flex="auto">Start: </Col>
                                <Col flex="auto">
                                    {displayDate(fromCurrWeek(item.start, new Date()))
                                                                    }
                                </Col>
                                <Col flex="auto"/>
                            </Row>
                            <br/>
                            <Row>
                            <Col flex="auto">{item?.end ? "End: " : null}</Col>
                                <Col flex="auto">
                                
                                {item?.end ? 
                                        displayDate(fromCurrWeek(item.end, new Date()))
                                    : null
                                }
                                </Col>
                                <Col flex="auto"/>
                            </Row>
                            <br/>
                            <Row>
                            <Col flex="auto">Every: </Col>
                                <Col flex="auto">
                                
                                {item.period} seconds
                                </Col>
                                <Col flex="auto"/>
                            </Row>
                        </Col>
                      
                    </Row>
                    
                ))
            } */}
       </Col>
        </div>
        </Row>
    )
};

export default connector(Timer);
