import React from 'react';
import { WarningTwoTone } from '@ant-design/icons';
import { Button } from 'antd';
// import "./index.css";

interface SpeciesProps {
    onViewChange: () => void;
  }

const Species:React.FC<SpeciesProps> = ({ onViewChange }) => {
    const species = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];

    return (
        <div style={{padding: "10px", backgroundColor: "#fff", borderRadius: "5px",border:"1px solid gray", boxShadow: "rgba(99, 99, 99, 0.3) 0px 2px 3px 0px", width: "30rem", display:"flex", justifyContent: "space-between"}}>
            <div>
                {species.map((item, index) => (
                    <div key={index} style={{padding: "2px"}}>
                        <span>aedes atlanticus</span>&nbsp;&nbsp;<WarningTwoTone />
                    </div>
                ))}
            </div>
            <Button type="primary" onClick={onViewChange}>Edit</Button>
        </div>           
)};

export default Species;