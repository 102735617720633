import { Dispatch } from "redux";
import {
  SendRequestCallback,
  updateResponse,
  PutDeviceCommandAction,
  CreateNotificationAction,
} from "../../types";
import { displayErrorNotification } from "../notifications";
import { PUT_DEVICE_COMMAND } from "../const";

export const putDeviceCommand =
  (
    sendRequest: SendRequestCallback<undefined, undefined, updateResponse>,
    uuid: string,
    command: string,
  ) =>
  async (dispatch: Dispatch<PutDeviceCommandAction | CreateNotificationAction>) => {
    try {
      const res = await sendRequest("PUT")({
        url: `/device/${uuid}/command/${command}`,
      });
      if (res?.status === 200) {
        dispatch({
          type: PUT_DEVICE_COMMAND,
          payload: null,
        });
      } else {
        throw Error("Status != 200");
      }
    } catch (error) {
      console.error("Failed to set command!!");
      await displayErrorNotification("Failed to set command")(dispatch);
    }
  };

  