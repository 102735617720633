import React, { useState, useEffect, useCallback } from "react";
import BluetoothSetup from "./BluetoothSetup";
import { ConnectedProps, connect } from "react-redux";
import {
  displaySuccessNotification,
  displayErrorNotification,
} from "../../actions";
import { BLUETOOTH_FILTER_PREFIX, BLUETOOTH_STATES, bleInfoTable } from "./const";
import { BluetoothCharacteristicCache, BluetoothServiceCache, ReducerState, WifiConnectArgs } from "../../types";
import { exponentialBackoff, getCharacteristicValue, setCharacteristicValue, time } from "./utils";

const mapStateToProps = (state: ReducerState) => {
  return {
      selected: state.devices?.selected?.uuid || null,
      filterStr: BLUETOOTH_FILTER_PREFIX
  }
}

const connector = connect(mapStateToProps, {
  displaySuccessNotification,
  displayErrorNotification,
});

type Props = ConnectedProps<typeof connector> & {
  handleClose: () => void,
};

const Index: React.FC<Props> = ({
  selected,
  filterStr,
  handleClose,
  displaySuccessNotification,
  displayErrorNotification,
}) => {
  const [bluetoothDevice, setBluetoothDevice] = useState<BluetoothDevice | null>(null);
  const [bluetoothGattServer, setBluetoothGattServer] = useState<BluetoothRemoteGATTServer | null>(null);
  const [services, setServices] = useState<Partial<BluetoothServiceCache>>({});
  const [characteristics, setCharacteristics] = useState<Partial<BluetoothCharacteristicCache>>({});
  const [isWifiConnected, setIsWifiConnected] = useState(false);
  const [currentNetwork, setCurrentNetwork] = useState<string|null>(null);
  const [wifiList, setWifiList] = useState<string[]>([]);
  const [bluetoothState, setBluetoothState] = useState(BLUETOOTH_STATES.DISCONNECTED);

  const onResetBT = useCallback(async () => {
    if (bluetoothState !== BLUETOOTH_STATES.CONNECTING) {
      if (bluetoothGattServer && bluetoothGattServer.connected) {
        bluetoothGattServer.disconnect();
      }
      // setBluetoothDevice(null);
      setBluetoothGattServer(null);
      setServices({});
      setCharacteristics({});
      setIsWifiConnected(false);
      setCurrentNetwork(null);
      setWifiList([]);
      setBluetoothState(BLUETOOTH_STATES.CONNECTING);
    }
  }, [bluetoothGattServer, bluetoothState])

  // check for unsupported
  useEffect(() => {
    if (!navigator?.bluetooth) {
      setBluetoothState(BLUETOOTH_STATES.UNSUPPORTED);
    }
  }, []);

  // // Print bluetoothState
  // useEffect(() => {
  //   console.log(bluetoothState);
  // }, [bluetoothState])

  // request device
  useEffect(() => {
    const connectDeviceWithBackoff = async () => {
      exponentialBackoff(
        3 /* max retries */,
        2 /* seconds delay */,
        async () => {
          time('Connecting to Bluetooth Device... ');
          await bluetoothDevice?.gatt?.connect?.();
        },
        () => {
          console.log('> Bluetooth Device connected.');
        },
        () => {
          time('Failed to reconnect.');
        });
    }
  
    const getDevice = async () => {
      try {
        const device = await navigator.bluetooth.requestDevice({
          filters: [{namePrefix: filterStr}],
          optionalServices: Object.values(bleInfoTable.services)
        });
        device.addEventListener('gattserverdisconnected', () => connectDeviceWithBackoff());
        setBluetoothDevice(device);
      } catch (error) {
       console.error(`Failed to find bluetooth device: ${error}`);
       setBluetoothState(BLUETOOTH_STATES.DISCONNECTED);
      }
      
    }
    if (bluetoothState === BLUETOOTH_STATES.CONNECTING && !bluetoothDevice) {
      getDevice();
    }
  }, [bluetoothState, bluetoothDevice, filterStr])
  
  // connect to server
  useEffect(() => {
    const connectGatt = async () => {
      if (bluetoothDevice?.gatt) {
        const server = await bluetoothDevice?.gatt.connect();
        setBluetoothGattServer(server);
      }
    }
    if (bluetoothDevice && bluetoothState === BLUETOOTH_STATES.CONNECTING) {
      connectGatt();
    }
  }, [bluetoothDevice, bluetoothState])

  // cache services
  useEffect(() => {
    const cacheServices = async () => {
      if (bluetoothGattServer) {
        const btservices: BluetoothServiceCache = Object.fromEntries(
          await Promise.all(
            Object.entries(bleInfoTable.services).map(async ([serviceName, serviceUuid]) => {
              const service = await bluetoothGattServer.getPrimaryService(serviceUuid);
              return [serviceName, service];
        })));
        setServices(btservices);
      }
    }

    try {
      cacheServices();
    } catch (error) {
      console.error(error);
    }
  }, [bluetoothGattServer]);

  // cache characteristics
  useEffect(() => {
    const cacheCharacteristics = async () => {
      if (Object.entries(services).length > 0) {
        const chars: BluetoothCharacteristicCache = Object.fromEntries(await Promise.all(Object.entries(services).map(async ([serviceName, service]) => {
          return [serviceName, Object.fromEntries(await Promise.all(Object.entries(bleInfoTable.characteristics[serviceName as 'scan' | 'connect']).map(async ([characteristicName, characteristicUuid]) => {
            return [characteristicName, await service.getCharacteristic(characteristicUuid)]
          })))]
        })))
        setCharacteristics(chars);
      }
    }
    
    try {
      cacheCharacteristics();
    } catch (error) {
      console.error(error);
    }
  }, [services])

  // set active listener
  useEffect(() => {
    const startScanNotifications = async () => {
      if (characteristics?.scan?.active) {
        try {
          const ret = await getCharacteristicValue(characteristics.scan.active);
          if (ret === null) {
            throw Error("Scan/active failed");
          } else {
            setIsWifiConnected(ret === 'T' ? true : false);
            setTimeout(startScanNotifications, 3000);
          }
        } catch (error) {
          onResetBT();
        } 
      }
    }

    const startWifiConnectedNotifications = async () => {
      if (Object.entries(characteristics).length > 0) {
        if (characteristics?.connect && characteristics?.scan) {
          if (Object.entries(characteristics?.connect || {}).length > 0) {
            if (Object.entries(characteristics?.scan || {}).length > 0) {
              // start active listener
              await startScanNotifications();
              setBluetoothState(BLUETOOTH_STATES.SCANNING);
              return;
            }
          }
        }
      }
    }

    if (bluetoothState === BLUETOOTH_STATES.CONNECTING) {
      try {
        startWifiConnectedNotifications();
      } catch (error) {
        console.error(error);
      }
      
    }
    
  },[characteristics, bluetoothState, onResetBT])

  // get wifi networks
  useEffect(() => {
    const startScanningWifi = async () => {
      if (characteristics?.connect && characteristics?.scan) {
        const ssids = (
          await Promise.all([
            characteristics.scan.list1,
            characteristics.scan.list2,
            characteristics.scan.list3,
            characteristics.scan.list4,
            characteristics.scan.list5
          ]
          .map(async (c) => {
            const val = getCharacteristicValue(c);
            if (val === null) {
              console.log('failure to startScanningWifi')
              onResetBT();
            }
            return val;
          }))
        )
        .join('')
        .split(',')
        .map((ssid) => {
          const[name, connected, ] = ssid.split('$');
          if (connected === '1') {
            setCurrentNetwork(name);
          }
          return name;
        })
        .filter((ssid) => !!ssid);
        setWifiList(ssids);
        if (Array.isArray(ssids) && ssids.length > 0) {
          setBluetoothState(BLUETOOTH_STATES.CONNECTED);
        }
        else {
          setTimeout(startScanningWifi, 1000);
        }
      }
      else {
        console.log("Scan Error: Bad characteristics")
      }
    }
    if (bluetoothState === BLUETOOTH_STATES.SCANNING) {
      startScanningWifi();
    }
  },[characteristics, bluetoothState, onResetBT])


  // disconnect device
  useEffect(() => {
    if (bluetoothState === BLUETOOTH_STATES.DISCONNECTED) {
      if (bluetoothGattServer && bluetoothGattServer.connected) {
        bluetoothGattServer.disconnect();
      }
      setBluetoothDevice(null);
      setBluetoothGattServer(null);
      setServices({});
      setCharacteristics({});
      setIsWifiConnected(false);
      setCurrentNetwork(null);
      setWifiList([]);
    }
    
  },[bluetoothState, bluetoothGattServer])


  // actions
  const onScanButtonPressed = async () => {
    setBluetoothState(BLUETOOTH_STATES.CONNECTING);
  }

  const onCancelButtonPressed = async () => {
    setBluetoothState(BLUETOOTH_STATES.DISCONNECTED);
  }

  const onRescanButtonPressed = async () => {
    if (bluetoothState === BLUETOOTH_STATES.CONNECTED) {
      setBluetoothState(BLUETOOTH_STATES.SCANNING);
    }
  }



  const onConnectButtonPressed = async (values: WifiConnectArgs) => {
    if (bluetoothState === BLUETOOTH_STATES.CONNECTED) {
      const ssid = values?.ssid || "";
      const psk = values?.psk || "";
      if (characteristics?.connect?.wpa) {
        setBluetoothState(BLUETOOTH_STATES.WIFI_CONNECTING);
        const prefix =
          ssid.length < 10
            ? ssid.length
            : String.fromCharCode("a".charCodeAt(0) + (ssid.length - 10));
        const buff = prefix + ssid + psk;
  
        try {
          const success = await setCharacteristicValue(characteristics.connect.wpa, buff);
          if (!success) {
            throw Error("CharacteristicValue failure");
          }
        } catch (err) {
          console.error("connect.wpa failed: " + err);
          onResetBT();
        }
        console.log(`connect.wpa finished`);
      }
    }
  }


  return (
    <BluetoothSetup
      bluetoothState={bluetoothState}
      handleClose={handleClose}
      handleConnectButtonPressed={onConnectButtonPressed}
      handleScanButtonPressed={onScanButtonPressed}
      handleRescanWifiButtonPressed={onRescanButtonPressed}
      handleCancelButtonPressed={onCancelButtonPressed}
      wifiList={wifiList}
      currentNetwork={currentNetwork}
      isWifiConnected={isWifiConnected || !!currentNetwork}
    />
  );
};

export default connector(Index);
