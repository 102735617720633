import React, { useState } from 'react';
import { WarningTwoTone } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';

interface SpeciesProps {
    onViewChange: () => void;
  }

const EditSpecies: React.FC<SpeciesProps> = ({ onViewChange }) => {
    const [species, setSpecies] = useState([
        {name: "aedes atlanticus1", inRegion: true, risk: "low-risk"},
        {name: "aedes atlanticus2", inRegion: true, risk: "high-risk"},
        {name: "aedes atlanticus3", inRegion: true, risk: "low-risk"},
        {name: "aedes atlanticus4", inRegion: false, risk: "low-risk"},
        {name: "aedes atlanticus5", inRegion: true, risk: "low-risk"},
        {name: "aedes atlanticus6", inRegion: false, risk: "high-risk"},
        {name: "aedes atlanticus7", inRegion: true, risk: "low-risk"},
        {name: "aedes atlanticus8", inRegion: true, risk: "low-risk"},
        {name: "aedes atlanticus9", inRegion: false, risk: "low-risk"},
        {name: "aedes atlanticus10", inRegion: false, risk: "high-risk"},
        {name: "aedes atlanticus11", inRegion: true, risk: "low-risk"},
        {name: "aedes atlanticus12", inRegion: true, risk: "high-risk"},
        {name: "aedes atlanticus13", inRegion: true, risk: "low-risk"},
        {name: "aedes atlanticus14", inRegion: false, risk: "low-risk"},
        {name: "aedes atlanticus15", inRegion: true, risk: "high-risk"},
        {name: "aedes atlanticus16", inRegion: false, risk: "low-risk"}
    ]);

    const changeRisk = (a: string, b: number) => {
        let _species = [...species];
        _species[b].risk = a;
        setSpecies([..._species]);
    };

    return (
        <div style={{padding: "10px", backgroundColor: "#fff", borderRadius: "5px",border:"1px solid gray", boxShadow: "rgba(99, 99, 99, 0.3) 0px 2px 3px 0px", width: "30rem", display:"flex", justifyContent: "space-between"}}>
            <div>
                {species.map((item, index) => (
                    <div key={index} style={{padding: "2px"}}>
                        <Popconfirm
                            title="Change the risk type"
                            okText="High risk"
                            cancelText="Low risk"
                            onConfirm={() => changeRisk("high-risk", index)}
                            onCancel={() => changeRisk("low-risk", index)}
                        >
                            <>
                                <a href="/#" onClick={(e) => e.preventDefault()}  style={{color: item.inRegion ? "#000" : "red"}}>
                                    {item?.name}
                                </a>&nbsp;&nbsp;{item?.risk === "high-risk" ? <WarningTwoTone /> : ""}
                            </>
                        </Popconfirm>
                    </div>
                ))}
            </div>
            <div>
                <Button type="primary" danger onClick={onViewChange}>RESTORE</Button>&nbsp;&nbsp;&nbsp;
                <Button type="primary" onClick={onViewChange}>SAVE</Button>
            </div>
            
        </div>           
)};

export default EditSpecies;