import { GET_ORGANIZATION } from "../actions";
import { OrganizationActions, OrganizationReducerState } from "../types";

const defaultState: Partial<OrganizationReducerState> = {};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = defaultState, action: OrganizationActions) => {
  switch (action.type) {
    case GET_ORGANIZATION:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
