import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Outlet } from 'react-router-dom';

export const AuthLayout = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect();
    };
    fn();
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  return isAuthenticated ? <Outlet /> : null;
};