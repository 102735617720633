import React from 'react';
import Schedule from '../../components/DevicePanel/Schedule';
import Timer from '../../components/Timer/index';
import DeviceSelector from '../../components/DeviceSelector';
import './index.css'
import { Col, Row } from 'antd';
const SchedulePage: React.FC = () => {



    return (
    <div className="component_box_container">
 
        <Col>
            <Row className="select_container">
                <DeviceSelector/>
            </Row>
            <Row >
                <Schedule/>
            </Row>
            <Row>
            <Col flex="auto"/>
                 <Timer/>
            <Col flex="auto"/>
            </Row>
        </Col>

    </div>
    )
}


export default SchedulePage;

// import React, {useState, useEffect} from 'react';
// import { Row, Col, Divider, Modal, Button, Form, Switch, InputNumber, Select, TimePicker, message, Spin, Popconfirm } from 'antd';
// import { ArrowLeftOutlined } from '@ant-design/icons';
// import { useNavigate, useParams } from 'react-router-dom';
// import ScheduleComponent from '../../components/DevicePanel/Schedule';
// import Timer from '../../components/Timer';
// import type { Dayjs } from 'dayjs';
// import moment from 'moment';
// import axios from 'axios';
// import { useWebServer } from '../../providers';
// import { timeAndWeekdayToSeconds, secondsToTimeAndWeekday } from '../../utils';
// import URL from '../../config/baseURL';

// const Schedule: React.FC = () => {
//     const { BASE_URL } = URL;
//     const [form] = Form.useForm();
//     const navigate = useNavigate();
//     const params = useParams();
//     const { getToken, isLoading } = useWebServer();
//     const [isModalVisible, setIsModalVisible] = useState(false);
//     const [allWeek, setAllWeek] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const [update, setUpdate] = useState(false);
//     const [scheduleId, setScheduleId] = useState(null);
//     const [timerKey, setTimerKey] = useState(0);
//     const weekDays = [
//         {label: "Monday", value: "Monday"},
//         {label: "Tuesday", value: "Tuesday"},
//         {label: "Wednesday", value: "Wednesday"},
//         {label: "Thursday", value: "Thursday"},
//         {label: "Friday", value: "Friday"},
//         {label: "Saturday", value: "Saturday"},
//         {label: "Sunday", value: "Sunday"}
//     ];
//     const [dataSource, setDataSource] = useState([]);

//     useEffect(() => {
//         getSchedules();
//     },[]);

//     useEffect(() => {
//         setTimerKey(prevKey => prevKey + 1);
//     }, [dataSource.length]);

//     // GET SCHEDULES START
//     const getSchedules = async() => {
//         setLoading(true);
//         const token = await getToken();
//         axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
//         axios.get(`${BASE_URL}device/${params?.id}/schedules`)
//             .then(function (response) {
//                 setDataSource(response?.data?.items);
//                 setLoading(false);
//               })
//               .catch(function (error) {
//                 console.log(error);
//                 setLoading(false);
//               })
//     };

//     // GET SCHEDULES END

//     // CREATE AND UPDATE SCHEDULE START
//     const handleSubmit = async (values: any) => {
//         setLoading(true);
//         let data = {
//             start: timeAndWeekdayToSeconds(values['start'].format('HH:mm'), values?.startDay), 
//             end: values?.end ? timeAndWeekdayToSeconds( values['end'].format('HH:mm'), values?.endDay) : null,
//             period: values?.period || 30,
//             enabled: true,
//         };

//         const token = await getToken();
//         axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
//         if(update){
//             axios.patch(`${BASE_URL}device/${params?.id}/schedule/`+ scheduleId, data)
//             .then(function (response) {
//                     setLoading(false);
//                     message.success("Schedule updated Successfully");
//                     handleCancel();
//                 })
//                 .catch(function (error) {
//                     setLoading(false);
//                     message.success("Failed to update schedule");
//                     handleCancel();
//                 })
//         }else{
//             axios.post(`${BASE_URL}device/${params?.id}/schedule`, data)
//             .then(function (response) {
//                     setLoading(false);
//                     message.success("Schedule Created Successfully");
//                     handleCancel();
//                 })
//                 .catch(function (error) {
//                     setLoading(false);
//                     message.success("Failed to create schedule");
//                     handleCancel();
//                 })
//         }
//     };
//     // CREATE AND UPDATE SCHEDULE END

//     // DELETE SCHEDULE START
//     const deleteSchedule = async() => {
//         setLoading(true);
//         const token = await getToken();
//         axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
//         axios.delete(`${BASE_URL}device/${params?.id}/schedule/`+ scheduleId)
//             .then(function (response) {
//                     setLoading(false);
//                     handleCancel();
//                     message.success("Schedule deleted");
//                 })
//                 .catch(function (error) {
//                     setLoading(false);
//                     // handleCancel();
//                     message.success("Failed to delete schedule");
//                 })
//     };
//     // DELETE SCHEDULE END

//     const openModal = () => {
//         setIsModalVisible(true);
//         form.resetFields(); 
//         setAllWeek(false);
//     };

//     const handleCancel = () => {
//         setIsModalVisible(false);
//         setUpdate(false);
//         setScheduleId(null);
//     };

//     const handleSwitch = (checked: boolean) => {
//         setAllWeek(checked);
//     };

//     const editTimer = (item: any) => {
//         setUpdate(true);
//         setScheduleId(item?._id);
//         setIsModalVisible(true);
//         setAllWeek(item?.end ? false : true);
//         form.setFieldsValue({
//             startDay: secondsToTimeAndWeekday(item.start).weekday,
//             start: moment(secondsToTimeAndWeekday(item?.start).time, "hh:mm A"),
//             endDay: item.end ? secondsToTimeAndWeekday(item.end).weekday : "",
//             end: item?.end ? moment(secondsToTimeAndWeekday(item?.end).time, "hh:mm A") : null,
//             period: item?.period || 30
//         })
//     }


//     return (
//         <div className="general_setting_container">
//             <Spin spinning={loading}>
//                 <Row>
//                     <Col span={2} />
//                     <Col span={20}>
//                         <Row>
//                             <Col span={24} className="heading_container">
//                             <ArrowLeftOutlined onClick={() => navigate("/device-panel")} style={{fontSize: "2.5rem", cursor: "pointer"}} />&nbsp;&nbsp;&nbsp;
//                                 <h3 style={{fontSize: "2rem"}}>Schedule - test-dev-3</h3>
//                             </Col>
//                             <Divider />
//                             <Col span={24} style={{display: "flex", justifyContent: "center"}}>
//                                 <ScheduleComponent />
//                             </Col>
//                             <Col span={24} style={{display: "flex", justifyContent: "center"}}>
//                                 <Timer key={timerKey} data={dataSource}  edit={editTimer} create={openModal}/>
//                             </Col>
//                         </Row>
//                     </Col>
//                     <Col span={2} />
                    
//                 </Row>
//                 <Modal
//                     visible={isModalVisible}
//                     width={650}
//                     title={update ? "Update Schedule & Timer" : "Add Schedule & Timer"}
//                     footer={null}
//                     onCancel={handleCancel}
//                 >
//                     <Spin spinning={loading}>
//                         <Form
//                             name="Timer"
//                             form={form}
//                             labelCol={{ span: 8 }}
//                             wrapperCol={{ span: 16 }}
//                             initialValues={{ remember: true }}
//                             onFinish={handleSubmit}
//                             layout='vertical'
//                             autoComplete="off"
//                         >
//                             <Row style={{alignItems: "center"}}>
//                                 <Col span={12}>
//                                 <Form.Item
//                                     label="Period"
//                                     name="period"
//                                     initialValue={30}
//                                 >
//                                     <InputNumber placeholder='Capture Period' min={1} style={{width: "10rem"}} />
//                                 </Form.Item>
//                                 </Col>
//                                 <Col span={4} >
//                                     <Switch value={allWeek} checkedChildren="All-Week" unCheckedChildren="All-Week" onChange={handleSwitch} />
//                                 </Col>
//                                 <Col span={12}>
//                                     <Form.Item
//                                         label="Start day"
//                                         name="startDay"
//                                         rules={[{ required: true, message: 'Please select start day' }]}
//                                         style={{marginBottom: "0.5rem"}}
//                                     >
//                                         <Select options={weekDays} />
//                                     </Form.Item>
//                                 </Col>
//                                 <Col span={12}>
//                                     <Form.Item
//                                         label="Start time"
//                                         name="start"
//                                         rules={[{ required: true, message: 'Please select start time' }]}
//                                     >
//                                         <TimePicker format="hh:mm A" />
//                                     </Form.Item>
//                                 </Col>
                                
//                             </Row>
//                             {allWeek ? "" :
//                             <Row style={{alignItems: "center"}}>
//                                 <Col span={12}>
//                                     <Form.Item
//                                         label="End day"
//                                         name="endDay"
//                                         rules={[{ required: allWeek ? false : true, message: 'Please select end day' }]}
//                                     >
//                                         <Select options={weekDays} />
//                                     </Form.Item>
//                                 </Col>
//                                 <Col span={12}>
//                                     <Form.Item
//                                         label="End time"
//                                         name="end"
//                                         rules={[{ required: allWeek ? false : true, message: 'Please select end time' }]}
//                                     >
//                                         <TimePicker format="hh:mm A" />
//                                     </Form.Item>
//                                 </Col>
//                             </Row>}
//                             <Row style={{justifyContent: "end"}}>
//                                 <Popconfirm title="Delete schedule"
//                                     description="Are you sure to delete this schedule?"
//                                     onConfirm={deleteSchedule}
//                                     okText="Yes"
//                                     cancelText="No"
//                                 >
//                                     <Button danger>Delete</Button>
//                                 </Popconfirm>&nbsp;&nbsp;
//                                 <Button key="back" onClick={handleCancel}>
//                                     Cancel
//                                 </Button>&nbsp;&nbsp;
//                                 <Button type="primary" htmlType="submit">
//                                     {update ? "Update" : "Submit"}
//                                 </Button>
//                             </Row>
//                         </Form>
//                     </Spin>
                    
//                 </Modal>
//             </Spin>
//         </div>
// )};

// export default Schedule;