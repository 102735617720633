import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Row, Col } from 'antd';
import "./index.css"
import DataWidget from "../../components/DevicePanel/DataWidget";
import ImageWidget from "../../components/DevicePanel/ImageWidget";
import MaintenanceAlert from '../../components/DevicePanel/MaintenanceAlert';
import WifiComp from '../../components/DevicePanel/Wifi';
import MapComponent from '../../components/DevicePanel/Map';
import Battery from '../../components/DevicePanel/Battery';
import Schedule from '../../components/DevicePanel/Schedule';
import DeviceSelector from '../../components/DeviceSelector';
import { ReducerState } from '../../types';

const mapStateToProps = (state: ReducerState) => {
    return {
        selected: state.devices?.selected?.uuid || null,
    }
  }
  
  const connector = connect(mapStateToProps, {
  });

  type Props = ConnectedProps<typeof connector> & {
    // Extra props go here
  };



const DevicePanel: React.FC<Props> = ({selected}) => {

    return (
        <div className="device_panel_container">
            <>
                <Row gutter={20} align={'stretch'} justify="center">
                    <Col span={20} className="grid-col select_container">
                        <DeviceSelector/>
                    </Col>
                </Row>
                
               { (selected) ? (<>
               <Row gutter={20} align={'stretch'} justify="center">
                    <Col className={"grid-col"} span={14}>
                        <MaintenanceAlert />
                    </Col>
                    
                    <Col className={"grid-col"} span={7}>
                        <ImageWidget />
                    </Col>

                    <Col className={"grid-col"} span={3}>
                        <Battery />
                    </Col>
                   
                </Row>
                <Row gutter={20} align={'stretch'} justify="center">
                <Col className={"grid-col"} span={10}>
                        <MapComponent/>
                    </Col>
                    <Col className={"grid-col"} span={14}>
                        <Schedule />
                    </Col>
                    
                    
                </Row>
                <Row gutter={20} align={'stretch'} justify="center">
                    
                    <Col className={"grid-col"} span={12}>
                        <DataWidget />
                    </Col>
                    <Col className={"grid-col"} span={12}>
                        <WifiComp />
                    </Col>
                    
                   
                </Row>
                </>) : (
                    <Row gutter={20} align={'stretch'} justify="center">
                     <Col className={"grid-col"} span={12}>
                         <MapComponent/>
                     </Col>
                     <Col className={"grid-col"} span={12}>
                         <MaintenanceAlert />
                     </Col>
                     </Row>
                )}
            </>
        </div>
    );
};

export default connector(DevicePanel);
