import { Dispatch } from "redux";
import {
  SendRequestCallback,
  CreateNotificationAction,
  listCapturesRequest,
  listCaptureResponse,
  ListDeviceCapturesAction,
} from "../../types";
import { displayErrorNotification } from "../notifications";
import { LIST_DEVICE_CAPTURES } from "../const";

export const listCaptures =
  (
    sendRequest: SendRequestCallback<
      undefined,
      listCapturesRequest,
      listCaptureResponse
    >,
    deviceUuid: string,
    params: listCapturesRequest
  ) =>
  async (
    dispatch: Dispatch<ListDeviceCapturesAction | CreateNotificationAction>
  ) => {
    try {
      const res = await sendRequest("GET")({
        url: `/device/${deviceUuid}/captures`,
        params,
      });
      if (res?.status === 200) {
        dispatch({
          type: LIST_DEVICE_CAPTURES,
          payload: {
            ...res.data,
            page: params?.page || 0,
            pageSize: params?.pageSize || 10,
            deviceUuid,
          },
        });
      } else {
        throw Error("Status != 200");
      }
    } catch (error) {
      await displayErrorNotification("Failed to list captures")(dispatch);
    }
  };
