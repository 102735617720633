export const getCharacteristicValue = async (
  characteristic: BluetoothRemoteGATTCharacteristic
) => {
  try {
    const enc = new TextDecoder("utf-8");
    const buf = await characteristic.readValue();
    const ret = enc.decode(buf);
    return ret;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const setCharacteristicValue = async (
  characteristic: BluetoothRemoteGATTCharacteristic,
  value: string
) => {
  try {
    const enc = new TextEncoder();
    const msg = enc.encode(value);
    await characteristic.writeValueWithResponse(msg);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

// export const toTitleCase = (str: string) => {
//   return str.replace(/\w\S*/g, function (txt) {
//     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//   });
// };

export async function exponentialBackoff<T>(
  max: number,
  delay: number,
  toTry: () => Promise<T>,
  success: (result: T) => void,
  fail: () => void
) {
  try {
    const result = await toTry();
    success(result);
  } catch (error) {
    if (max === 0) {
      return fail();
    }
    time("Retrying in " + delay + "s... (" + max + " tries left)");
    setTimeout(function () {
      exponentialBackoff(--max, delay * 2, toTry, success, fail);
    }, delay * 1000);
  }
}

export const time = (text: string) => {
  console.log("[" + new Date().toJSON().substr(11, 8) + "] " + text);
};
