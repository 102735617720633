import React, { useEffect, useState } from "react";
import {  Button, Col, Form, FormInstance, Input, Row, Select, Spin } from 'antd';
import './index.css'
import { WifiConnectArgs } from "../../types";
import { BLUETOOTH_STATES } from "./const";

type Props = {
  form: FormInstance<WifiConnectArgs>,
  bluetoothState: string,
  wifiList: string[],
  currentNetwork: string | null,
  isWifiConnected: boolean
  rescanButton: React.JSX.Element,
  cancelButton: React.JSX.Element,
  handleFinish: (values: WifiConnectArgs) => Promise<void>,
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 22 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
};

const DeviceSetupForm: React.FC<Props> = ({
  form,
  bluetoothState,
  wifiList,
  currentNetwork,
  isWifiConnected,
  rescanButton,
  cancelButton,
  handleFinish,
}) => {
  const [ssidSearch, setSsidSearch] = useState('');
  const [hasSelectedCurrent, setHasSelectedCurrent] = useState(false);

  const onSearch = (value: string) => {
    if (value.length > 0) {
      setSsidSearch(value);
    }
  }

  const onBlurSelect = () => {
    if ((ssidSearch?.length || 0) > 0) {
      form.setFieldValue('ssid', ssidSearch);
      setHasSelectedCurrent(ssidSearch === currentNetwork);
    }
  }

  const onClearSelect = () => {
    setSsidSearch("");
  }

  const onSsidSelect = () => {
    setSsidSearch("");
  }

  // update hasSelectedCurrent when isWifiConnected changes
  useEffect(() => {
    if (currentNetwork) {
      setHasSelectedCurrent(isWifiConnected && form.getFieldValue('ssid') === currentNetwork);
    }
    
  }, [isWifiConnected, currentNetwork, form])

  const handleSelectValueChange = (value: string) => {
    setHasSelectedCurrent(value === currentNetwork);
    form.setFieldValue('psk', '');
  }

  const ssidMenuItems = ([...wifiList, ...(!wifiList.includes(ssidSearch) ? [ssidSearch] : [])] || [ssidSearch]).map((ssid: string) => (
  {value: ssid, label: (<span>{ssid}</span>)}
  ))

  const wifiForm = (
    <Form
      {...formItemLayout}
      name={'wifi-form'}
      form={form}
      className="device-setup-form"
      onFinish={handleFinish}
      initialValues={{ssid: currentNetwork}}
      >
        <Form.Item
          name="Ssid"
          label=""
        >
          <Row gutter={2}>
            <Col span={22}>
              <Form.Item
                name="ssid"
                noStyle
              >
                <Select
                  id="ssid-select"
                  placeholder={"Select WiFi SSID"}
                  disabled={bluetoothState !== BLUETOOTH_STATES.CONNECTED}
                  loading={bluetoothState === BLUETOOTH_STATES.SCANNING}
                  allowClear
                  showSearch
                  onSearch={onSearch}
                  onBlur={onBlurSelect}
                  onClear={onClearSelect}
                  onSelect={onSsidSelect}
                  options={ssidMenuItems}
                  defaultValue={currentNetwork}
                  onChange={handleSelectValueChange}
                />
                 
              </Form.Item>
            </Col>
            <Col span={2}>
              {rescanButton}
            </Col>
          </Row>
        </Form.Item>
        <Form.Item name="psk" label="">
          <Input.Password 
          placeholder={"Password"}
          disabled={hasSelectedCurrent}
          />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Row 
            gutter={2}
            justify={'start'}
            align={"top"}
          >
            <Col span={16}>
              <Form.Item noStyle>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={bluetoothState === BLUETOOTH_STATES.WIFI_CONNECTING}
                  disabled={bluetoothState !== BLUETOOTH_STATES.CONNECTED || hasSelectedCurrent}
                >
                  Connect
                </Button>
              </Form.Item>
            </Col>
            <Col span={8}>
              {cancelButton}
            </Col>
          </Row>
        </Form.Item>
      </Form>
  );

  // main
  if (bluetoothState === BLUETOOTH_STATES.CONNECTING) {
    return <Spin/>
  }
  if ([
    BLUETOOTH_STATES.CONNECTED,
    BLUETOOTH_STATES.SCANNING,
    BLUETOOTH_STATES.WIFI_CONNECTING
  ].includes(bluetoothState)) {
    if (ssidMenuItems.length > 1) {
      return wifiForm;
    }
    else {
      return <Spin/>
    }
  } 
  return null;
};

export default DeviceSetupForm;
