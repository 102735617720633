import React, { useEffect, useState } from 'react';
import { Button, Spin, Tooltip } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Device } from '../../types';
import { LoadingOutlined, CloseCircleOutlined } from '@ant-design/icons';

const indicator = <LoadingOutlined style={{ fontSize: 15 }} spin />

type Props = {
  selected: Device | null;
  handleClickUpdate: () => Promise<void>
  handleCancelUpdate: () => Promise<void>
};

const UpdateButton: React.FC<Props> = ({selected, handleClickUpdate, handleCancelUpdate}) => {
  const [ loading, setLoading ] = useState(false);
  const [ message, setMessage ] = useState("Device unselected");
  
  const disabled = selected === null || !selected?.update_available;

  useEffect(() => {
    if (!selected) {
      setMessage("Device Unselected");
    }
    else if (!selected?.update_available) {
      setMessage("No update is available");
    }
    else if (selected?.will_update) {
      setMessage("Cancel Update");
    }
    else {
      setMessage("Schedule Update");
    }
  }, [selected, setMessage])

  const onClickUpdate = async () => {
    setLoading(true);
    await handleClickUpdate();
    setLoading(false);
  }

  const onCancelUpdate = async () => {
    setLoading(true);
    await handleCancelUpdate();
    setLoading(false);
  }

  return (
    <div className="update-button-container">
      <Spin spinning={loading} indicator={indicator}>
      <Tooltip title={message} style={{width: "4px"}}>
        <Button 
          type="primary"
          danger={selected?.will_update}
          shape="round"
          about={message}
          disabled={disabled}
          onClick={selected?.will_update ? onCancelUpdate : onClickUpdate}
          icon={selected?.will_update ? <CloseCircleOutlined /> : <CloudDownloadOutlined />}
        />
        </Tooltip>
      </Spin>
      </div>
  );
};

export default UpdateButton;
