import React from "react";
import {  Button, Col, Form, Row, Space, Spin } from 'antd';
import {ReactComponent as BluetoothIcon} from "../../assets/icons/bluetooth.svg";
import {SyncOutlined, CloseOutlined, ArrowLeftOutlined} from '@ant-design/icons';
import './index.css'
import { WifiConnectArgs } from "../../types";
import { BLUETOOTH_STATES } from "./const";
import DeviceSetupForm from "./DeviceSetupForm";
import WifiConnectedTag from "./WifiConnectedTag";

type Props = {
  bluetoothState: string,
  handleConnectButtonPressed: (values: WifiConnectArgs) => Promise<void>,
  handleScanButtonPressed: () => Promise<void>,
  handleRescanWifiButtonPressed: () => void,
  handleCancelButtonPressed: () => void,
  handleClose: () => void,
  wifiList: string[],
  currentNetwork: string | null,
  isWifiConnected: boolean
};

const BluetoothSetup: React.FC<Props> = ({
  bluetoothState,
  handleConnectButtonPressed,
  handleScanButtonPressed,
  handleRescanWifiButtonPressed,
  handleCancelButtonPressed,
  handleClose,
  isWifiConnected,
  ...props
}) => {
  const [form] = Form.useForm<WifiConnectArgs>();

  const onCancelButtonPressed = () => {
    form.resetFields();
    handleCancelButtonPressed();
  }

  const onCloseButtonPressed = () => {
    onCancelButtonPressed();
    handleClose();
  }

  // buttons
  const scanButton = (
    bluetoothState === BLUETOOTH_STATES.DISCONNECTED ? (
      <Row 
        justify={'center'}
        align={"middle"}
      >
        <Col flex="auto">
          <Row>
            <Button 
              onClick={handleScanButtonPressed}
              type="primary"
              shape="round"
              about="Scan"
              className="device-setup-scan-button"
              icon={<BluetoothIcon className='bluetooth-icon'/>}
            />
          </Row>
          <Row>
            <h3>Connect Device</h3>
          </Row>
        </Col>
      </Row>
    ) : null
  );

  const cancelButton = (
    <Button 
      onClick={onCancelButtonPressed}
      danger
      shape="round"
      about="Cancel"
      icon={<CloseOutlined className="device-setup-icon-button"/>}
    />
  );

  const rescanButton =  (bluetoothState === BLUETOOTH_STATES.CONNECTED ? (
    <Button 
      onClick={handleRescanWifiButtonPressed}
      shape="round"
      about="Rescan"
      icon={<SyncOutlined className="device-setup-icon-button"/>}
    />
  ) : (
    <Spin />
  ));

  const main = (
    <>
      {scanButton}
      <DeviceSetupForm
        form={form}
        bluetoothState={bluetoothState}
        isWifiConnected={isWifiConnected}
        rescanButton={rescanButton}
        cancelButton={cancelButton}
        handleFinish={handleConnectButtonPressed}
        {...props}
        />   
    </>
  );

  return (
    <Space size="large" className={"component_box_container"} id={"bluetooth-setup"}>
      <Button 
        onClick={onCloseButtonPressed}
        shape="round"
        about="Cancel"
        icon={<ArrowLeftOutlined className="device-setup-icon-button"/>}
        className="device-setup-close-button"
      />
      <WifiConnectedTag
        isWifiConnected={isWifiConnected}
        show={bluetoothState === BLUETOOTH_STATES.CONNECTED}
      />
      { bluetoothState === BLUETOOTH_STATES.UNSUPPORTED 
        ? <h3>This browser does not support WebBluetooth. Please use a supported browser such as Google Chrome for this feature.</h3>
        : main
      }
    </Space>    
  );
};

export default BluetoothSetup;
