import React from "react";
import {  Tag } from 'antd';
import './index.css'

type Props = {
  isWifiConnected: boolean;
  show: boolean;
};

const WifiConnectedTag: React.FC<Props> = ({
  isWifiConnected,
  show,
}) => {
  if (show) {
    return (
      <Tag className="device-setup-connected-tag"  color={isWifiConnected ? "green" : "red"}>
        <span>{isWifiConnected ? "Device Connected" : "Device Disconnected"}</span>
      </Tag>
    );
  }
  return null;

};

export default WifiConnectedTag;