// notifications
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// alerts
export const LIST_ALERTS = "LIST_ALERTS";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const CLEAR_ALERTS = "CLEAR_ALERTS";

// devices
export const LIST_DEVICES = "LIST_DEVICES";
export const SELECT_DEVICE = "SELECT_DEVICE";
export const UNSELECT_DEVICE = "UNSELECT_DEVICE";
export const SCHEDULE_DEVICE_UPDATE = "SCHEDULE_DEVICE_UPDATE";
export const DISPLAY_NAME_UPDATE = "DISPLAY_NAME_UPDATE";

// captures
export const LIST_DEVICE_CAPTURES = "LIST_DEVICE_CAPTURES";

// schedules
export const GET_NEXT_DEVICE_SCHEDULE = "GET_NEXT_DEVICE_SCHEDULE";
export const LIST_DEVICE_SCHEDULES = "LIST_DEVICE_SCHEDULES";
export const CREATE_DEVICE_SCHEDULE = "CREATE_DEVICE_SCHEDULE";
export const UPDATE_DEVICE_SCHEDULE = "UPDATE_DEVICE_SCHEDULE";
export const DELETE_DEVICE_SCHEDULE = "DELETE_DEVICE_SCHEDULE";

// analytics
export const GET_DEVICE_ANALYTICS = "GET_DEVICE_ANALYTICS";

// organization
export const GET_ORGANIZATION = "GET_ORGANIZATION";

// device command
export const PUT_DEVICE_COMMAND = "PUT_DEVICE_COMMAND";
