import { BluetoothInfoTable } from "../../types";

export const BLUETOOTH_FILTER_PREFIX: string = "Scout ";

export const bleInfoTable: BluetoothInfoTable = {
  services: {
    scan: "0a70321c-3679-44f4-9180-2715162d1fc7",
    connect: "4e9b31c3-f6a7-4c91-a0cf-ad0d09f8ede2",
  },
  characteristics: {
    scan: {
      active: "869e4c2d-2df4-48ff-a61d-07f8cdd58341",
      list1: "30fbbc2c-adce-4795-af91-7cda7e681cb8",
      list2: "c2c72213-752f-4d48-af7c-efed87edadb9",
      list3: "00a5dd72-01b5-44f2-9b73-777bd6da3dda",
      list4: "d1f34740-cade-47b5-8d02-51ab534ad711",
      list5: "8d9f4d87-1be9-4fd1-a564-531401d2dd11",
    },
    connect: {
      wpa: "807ba565-30b5-435b-9c42-c01778aadd50",
      wclose: "75527e54-d26a-4b6d-b468-b66d81598f5c",
    },
  },
};

export const BLUETOOTH_STATES = {
  UNSUPPORTED: "unsupported",
  DISCONNECTED: "disconnected",
  CONNECTING: "connecting",
  SCANNING: "scanning",
  CONNECTED: "connected",
  WIFI_CONNECTING: "wifi_connecting",
};
