import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { thunk } from 'redux-thunk'
import App from './App';
import reducers from './reducers'
import reportWebVitals from './reportWebVitals';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import WebServerProvider from './providers/WebServerProvider';
import { Provider } from 'react-redux';
import { EnhancedStore, Tuple, configureStore } from '@reduxjs/toolkit';


const ReduxProvider: React.FC = () => {
  const { isLoading } = useAuth0();
  const [store, setStore] = useState<EnhancedStore>();
  useEffect(() => {
    const makeStore = async () => {
      const s = configureStore({
        reducer: reducers,
        middleware: () => new Tuple(thunk),
        devTools: process.env.REACT_APP_ENV !== 'production' ? {
          trace: true,
          traceLimit: 25,
        } : false
      })
      setStore(s as any);
    };
    if (isLoading === false) makeStore();
  }, [isLoading]);

  return store ? (
    <Provider store={store}>
      <App />
    </Provider>
  ) : null;
};


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Auth0Provider
    domain={process.env?.REACT_APP_AUTH0_DOMAIN || ""}
    clientId={process.env?.REACT_APP_AUTH0_CLIENT_ID || ""}
    authorizationParams={{
      audience: process.env?.REACT_APP_AUTH0_AUDIENCE || "",
      redirect_uri: window.location.origin,
    }}
    // onRedirectCallback={onRedirectCallback}
    cacheLocation={"localstorage"}
  >
    <WebServerProvider>
      <ReduxProvider />
    </WebServerProvider>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
