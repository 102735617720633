import {
  LIST_DEVICES,
  SCHEDULE_DEVICE_UPDATE,
  SELECT_DEVICE,
  UNSELECT_DEVICE,
  DISPLAY_NAME_UPDATE
} from "../actions";
import _ from "lodash";
import { DeviceActions, DeviceReducerState } from "../types";

const defaultState: DeviceReducerState = {
  count: 0,
  devices: {},
  selected: null,
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = defaultState, action: DeviceActions) => {
  switch (action.type) {
    case LIST_DEVICES:
      return {
        devices: action.payload.items.reduce(
          (o, x) => (x.uuid ? { ...o, [x.uuid]: x } : o),
          {}
        ),
        ..._.omit(action.payload, "items"),
      };
    case SELECT_DEVICE:
      return {
        ...state,
        selected: action.payload,
      };
    case UNSELECT_DEVICE:
      return {
        ...state,
        selected: null,
      };
    case SCHEDULE_DEVICE_UPDATE:
      return {
        ...state,
        devices: {
          ...state.devices,
          [action.payload.deviceUuid]: {
            ...(state.devices?.[action.payload.deviceUuid] || {}),
            will_update: action.payload.will_update,
          },
        },
        selected:
          state?.selected?.uuid === action?.payload?.deviceUuid
            ? { ...state.selected, will_update: action.payload.will_update }
            : state.selected,
      };
    case DISPLAY_NAME_UPDATE:
      return {
        ...state,
        devices: {
          ...state.devices,
          [action.payload.deviceUuid]: {
            ...(state.devices?.[action.payload.deviceUuid] || {}),
            display_name: action.payload.display_name,
          },
        },
        selected:
          state?.selected?.uuid === action?.payload?.deviceUuid
            ? { ...state.selected, display_name: action.payload.display_name }
            : state.selected,
    };
    default:
      return state;
  }
};
