import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Card, Tooltip, Space } from 'antd';
import { ExclamationCircleOutlined, PictureTwoTone, SyncOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';
import { ReducerState } from '../../../types';
import { listCaptures } from '../../../actions';
import { useWebServer } from '../../../providers';
import './index.css'
import ImageView from '../../ImageView';

const { Meta } = Card;

const mapStateToProps = (state: ReducerState) => {
    console.log(Object.values(state.captures.captures)[0])
    return {
        selected: state.devices?.selected?.uuid || null,
        count: state.captures.count,
        captures: state.captures.captures,
        lastCapture: Object.values(state.captures.captures)[0],
        deviceUuid: state.captures.deviceUuid,
    }
}

const connector = connect(mapStateToProps, {
    listCaptures
});

type Props = ConnectedProps<typeof connector> & {
    // Extra props go here
};

const ImageWidget: React.FC<Props> = ({listCaptures, selected, count, captures, lastCapture, deviceUuid}) => {
    const navigate = useNavigate();
    const { sendRequest, isLoading } = useWebServer();

    useEffect(() => {
        if (!isLoading && selected && selected !== deviceUuid) {
            listCaptures(sendRequest, selected, {page: 0, pageSize: 1});
        }
    }, [isLoading, count, selected, deviceUuid, sendRequest, listCaptures]);

    const displayDate = (captured_at: string) => {
        if (captured_at) {
          const d = new Date(captured_at + (captured_at.endsWith('+00:00') ? "" : "+00:00"));
          const dateString = d.toLocaleString().replace(',', ' -');
          return dateString
        }
        return '';
      }
    
    const handleRefresh = () => {
        if (selected) {
            listCaptures(sendRequest, selected, {page: 0, pageSize: 1});
        }
    }

    const handleGallery = () => {
        if (!!lastCapture?.img) {
            navigate(ROUTES?.gallery)
        }
    }

    return (
        <Space wrap className="component_box_container">
            <Card
                cover={<ImageView src={lastCapture?.img || null} className="spec_style"/>}
                actions={[
                    (<PictureTwoTone
                         key="gallery"
                         disabled={!lastCapture?.img}
                         onClick={handleGallery}
                    />),
                    (<SyncOutlined
                        key="refresh"
                        disabled={selected === null}
                        onClick={handleRefresh}
                    />)
                ]}
                >
                
                <Meta 
                avatar={(
                    <Tooltip title="Failed Upload">
                    <ExclamationCircleOutlined
                    className="red-icon"
                    style={(lastCapture?.failed || false) ? {} : {visibility: 'hidden'} }
                    />
                    </Tooltip>
                    ) }
                description={displayDate(lastCapture?.captured_at)}
                />  
            </Card>

            {/* <ImageView height={170} src={lastCapture?.img || null} />
            &nbsp;&nbsp;
            <div>
                <Row justify="center">
                    <Col flex={"auto"}><h3>Last Capture</h3></Col>
                    <Col span={1}/>
                    <Col flex={"auto"}><PictureTwoTone style={{fontSize: "2rem", cursor: "pointer"}} onClick={!!lastCapture?.img ? ()=> navigate(ROUTES?.gallery) : () => console.log("No Data")} /></Col>
                    
                </Row>
                <Row justify='start'>
                    <Col span={"auto"}> <h4 style={{margin: "0px", color: 'grey'}}>{displayDate(lastCapture?.captured_at)}</h4></Col>
                </Row>
            </div> */}
        </Space>
    )
};

export default connector(ImageWidget);