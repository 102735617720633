import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Progress, Space, Spin } from 'antd';
import { SettingFilled } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';
import { ReducerState } from '../../../types';
import { getAnalytics } from '../../../actions';
import { useWebServer } from '../../../providers';

const mapStateToProps = (state: ReducerState) => {    
    return {
        selected: state.devices?.selected?.uuid || null,
        ...state.analytics,
    }
  }
  
  const connector = connect(mapStateToProps, {
    getAnalytics
  });

  type Props = ConnectedProps<typeof connector> & {
    // Extra props go here
  };

const DataWidget: React.FC<Props> = ({getAnalytics, lowRiskSpecies, percCaptures, totalCaptures, deviceUuid, selected}) => {
    const { sendRequest} = useWebServer();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (selected && selected !== deviceUuid) {
          getAnalytics(sendRequest, selected);
        }
    }, [getAnalytics, sendRequest, deviceUuid, selected])

    return (
        <Space wrap className="component_box_container">
            {(selected && selected === deviceUuid) ? (
                <>
            <div>
                <h4>Total Captures</h4>
                <h2>{totalCaptures || "No Captures"}</h2>
            </div>
            &nbsp;&nbsp;
            <div>
                <h4>Valid Captures</h4>
                <Progress type="circle" percent={percCaptures} size={145} />
            </div>
            &nbsp;&nbsp;
            <div>
                <h4>Low-risk Species</h4>
                <Progress type="circle" percent={lowRiskSpecies} size={145} format={() => lowRiskSpecies || 0 + "%"} />
            </div>
            &nbsp;&nbsp;
            <SettingFilled onClick={() => navigate(ROUTES.settings)} style={{fontSize: "3rem", cursor: "pointer"}}/>
            </>) : (
                <>
                <Spin size="large"/>
                </>
            )}
            
        </Space>
    )
};

export default connector(DataWidget);