import {
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  CLEAR_ERRORS,
} from "../actions";
import _ from "lodash";
import { NotificationActions, NotificationReducerState } from "../types";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
const defaultState: NotificationReducerState = { count: 0, notifications: {} };
export default (state = defaultState, action: NotificationActions) => {
  switch (action.type) {
    case CREATE_NOTIFICATION:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [action.payload?.id]: action.payload,
        },
        count: state.count + 1,
      };
    case DELETE_NOTIFICATION:
      const deleted = _.omit(state.notifications, action.payload);
      return {
        notifications: deleted,
        count: Object.keys(deleted).length,
      };
    case CLEAR_NOTIFICATIONS:
      return defaultState;
    case CLEAR_ERRORS:
      const cleared = Object.entries(state.notifications).reduce(
        (o, [k, v]) => (v.severity !== "error" ? { ...o, [k]: v } : o),
        {}
      );
      return {
        notifications: cleared,
        count: Object.keys(cleared).length,
      };
    default:
      return state;
  }
};
