import {
  LIST_ALERTS,
  SELECT_DEVICE,
  UNSELECT_DEVICE,
  UPDATE_ALERT,
  CLEAR_ALERTS
} from "../actions";
import _ from "lodash";
import {
  AlertActions,
  AlertReducerState,
  SelectDeviceAction,
  UnselectDeviceAction,
} from "../types";

const defaultState: AlertReducerState = {
  count: 0,
  alerts: {},
  page: 0,
  pageSize: 10,
  deviceUuid: null,
  tag: "",
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (
  state = defaultState,
  action: AlertActions | SelectDeviceAction | UnselectDeviceAction
) => {
  switch (action.type) {
    case UNSELECT_DEVICE:
      return defaultState;
    case SELECT_DEVICE:
      if (state.deviceUuid !== action.payload.uuid) {
        return defaultState;
      }
      return state;
    case LIST_ALERTS:
      return {
        alerts: action.payload.items.reduce(
          (o, x) => (x._id ? { ...o, [x._id]: x } : o),
          {}
        ),
        ..._.omit(action.payload, "items"),
      };
    case UPDATE_ALERT:
      if (action.payload._id && action.payload?._id in state.alerts) {
        if (action.payload?.seen) {
          // delete it from alerts
          return {
            ...state,
            alerts: _.omit(state.alerts, action.payload._id),
            count: state.count - 1,
          };
        } else {
          // update payload
          return _.defaultsDeep(
            {
              alerts: {
                [action.payload._id]: { ...action.payload },
              },
            },
            state
          );
        }
      }
      return state;
    case CLEAR_ALERTS:
        if (action.payload.tags && action.payload?.deviceUuid) {
          if (action.payload?.success) {
            let fillteredArray = _.filter(state.alerts, alert => !( _.isEqual(alert.device, action.payload?.deviceUuid) && action.payload.tags.includes(alert.tag)));
            return {
              ...state,
              alerts: fillteredArray,
              count: state.count - fillteredArray.length,
            };
          }
        }
        return state;

    default:
      return state;
  }
};
