import { Dispatch } from "redux";
import {
  SendRequestCallback,
  CreateNotificationAction,
  listDevicesReponse,
  ListDevicesAction,
  SelectDeviceAction,
  Device,
  UnselectDeviceAction,
  ScheduleDeviceUpdateAction,
  scheduleDeviceUpdateRequest,
  scheduleDeviceUpdateResponse,
  displayNameUpdateRequest,
  displayNameUpdateResponse,
  DisplayNameUpdateAction
} from "../../types";
import { displayErrorNotification } from "../notifications";
import {
  SELECT_DEVICE,
  LIST_DEVICES,
  UNSELECT_DEVICE,
  SCHEDULE_DEVICE_UPDATE,
  DISPLAY_NAME_UPDATE,
} from "../const";

export const listDevices =
  (
    sendRequest: SendRequestCallback<undefined, undefined, listDevicesReponse>
  ) =>
  async (dispatch: Dispatch<ListDevicesAction | CreateNotificationAction>) => {
    try {
      const res = await sendRequest("GET")({
        url: `/devices`,
      });
      if (res?.status === 200) {
        dispatch({
          type: LIST_DEVICES,
          payload: res.data,
        });
      } else {
        throw Error("Status != 200");
      }
    } catch (error) {
      await displayErrorNotification("Failed to list devices")(dispatch);
    }
  };

export const selectDevice =
  (
    sendRequest: SendRequestCallback<undefined, undefined, Device>,
    deviceUuid: string | null
  ) =>
  async (
    dispatch: Dispatch<
      SelectDeviceAction | UnselectDeviceAction | CreateNotificationAction
    >
  ) => {
    try {
      if (!deviceUuid) {
        dispatch({
          type: UNSELECT_DEVICE,
          payload: null,
        });
      } else {
        const res = await sendRequest("GET")({
          url: `/device/${deviceUuid}`,
        });
        if (res?.status === 200) {
          dispatch({
            type: SELECT_DEVICE,
            payload: res.data,
          });
        } else {
          throw Error("Status != 200");
        }
      }
    } catch (error) {
      await displayErrorNotification("Failed to fetch device")(dispatch);
    }
  };

export const scheduleDeviceUpdate =
  (
    sendRequest: SendRequestCallback<
      scheduleDeviceUpdateRequest,
      undefined,
      scheduleDeviceUpdateResponse
    >,
    deviceUuid: string,
    will_update: boolean
  ) =>
  async (
    dispatch: Dispatch<ScheduleDeviceUpdateAction | CreateNotificationAction>
  ) => {
    try {
      const res = await sendRequest("PUT")({
        url: `/device/${deviceUuid}/firmware`,
        data: {
          will_update: will_update,
        },
      });
      if (res?.status === 200) {
        dispatch({
          type: SCHEDULE_DEVICE_UPDATE,
          payload: { deviceUuid, ...res.data },
        });
      } else {
        throw Error("Status != 200");
      }
    } catch (error) {
      await displayErrorNotification("Failed to schedule device update")(
        dispatch
      );
    }
  };

export const displayNameUpdate =
  (
    sendRequest: SendRequestCallback<
      displayNameUpdateRequest,
      undefined,
      displayNameUpdateResponse
    >,
    deviceUuid: string,
    display_name: string | undefined | ""
  ) =>
  async (
    dispatch: Dispatch<DisplayNameUpdateAction | CreateNotificationAction>
  ) => {
    try {
      const res = await sendRequest("PUT")({
        url: `/device/${deviceUuid}`,
        data: {
          display_name: display_name,
        },
      });
      if (res?.status === 200) {
        dispatch({
          type: DISPLAY_NAME_UPDATE,
          payload: { deviceUuid, ...res.data },
        });
      } else {
        throw Error("Status != 200");
      }
    } catch (error) {
      await displayErrorNotification("Failed to update display name")(
        dispatch
      );
    }
  };
